import UserIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Key';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Tooltip,
	useTheme as useMuiTheme,
} from '@mui/material';
import React, { useState } from 'react';
import LogoDark from '../assets/logo_dark.svg';
import LogoLight from '../assets/logo_light.svg';
import { useIsMobile } from '../hooks';
import { I18NKey, useI18N } from '../i18n';
import { useRedux } from '../redux';
import { Auth } from '../services/Auth';
import { RequestError } from '../services/RequestError';

export interface LoginFormErrors {
	user: I18NKey;
	password: I18NKey;
}

export const LoginForm = (): JSX.Element => {
	const { palette } = useMuiTheme();

	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { snackbar, spinner } = useRedux();

	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');
	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const [errors, setErrors] = useState<LoginFormErrors>({
		user: '',
		password: '',
	});

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			void handleLogin();
		}
	};

	const handleLogin = async () => {
		const newErrors: LoginFormErrors = {
			user: '',
			password: '',
		};
		let hasErrors = false;

		if (!user) {
			newErrors.user = 'auth.errors.user.empty';
			hasErrors = true;
		}

		if (!password) {
			newErrors.password = 'auth.errors.password.empty';
			hasErrors = true;
		}

		if (hasErrors) {
			setErrors(newErrors);

			return;
		}

		spinner.show();

		try {
			await Auth.login(user, password);

			spinner.hide();
			snackbar.show({ status: 'success', message: 'auth.success.login' });
		} catch (err) {
			spinner.hide();

			if (err instanceof RequestError && typeof err.data === 'string') {
				switch (err.data) {
					case 'user-not-found':
						setErrors((prevErrors) => ({
							...prevErrors,
							user: 'auth.errors.user.notFound',
						}));

						return;

					case 'wrong-password':
						setErrors((prevErrors) => ({
							...prevErrors,
							password: 'auth.errors.password.wrong',
						}));

						return;

					default:
						break;
				}
			}

			snackbar.show({ status: 'error', message: 'auth.errors.login' });
		}
	};

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 2,
				py: isMobile ? 4 : 6,
				px: isMobile ? 2 : 4,
			}}
		>
			<Box
				component="img"
				src={palette.mode === 'light' ? LogoLight : LogoDark}
				alt={i18n('app.title.short')}
				width={200}
			/>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 2,
					width: isMobile ? 250 : 300,

					'> *': {
						width: 1,
					},
				}}
			>
				<TextField
					id="user"
					type="email"
					label={i18n('auth.user')}
					value={user}
					error={!!errors.user}
					helperText={errors.user ? i18n(errors.user) : ''}
					onKeyDown={handleKeyDown}
					onChange={(e) => {
						setUser(e.target.value);

						if (errors.user) {
							setErrors((prevErrors) => ({
								...prevErrors,
								user: '',
							}));
						}
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<UserIcon />
							</InputAdornment>
						),
					}}
				/>

				<TextField
					id="password"
					type={isPasswordVisible ? 'text' : 'password'}
					label={i18n('auth.password')}
					value={password}
					error={!!errors.password}
					helperText={errors.password ? i18n(errors.password) : ''}
					onKeyDown={handleKeyDown}
					onChange={(e) => {
						setPassword(e.target.value);

						if (errors.password) {
							setErrors((prevErrors) => ({
								...prevErrors,
								password: '',
							}));
						}
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<PasswordIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip
									title={i18n(isPasswordVisible ? 'auth.password.hide' : 'auth.password.show')}
								>
									<IconButton onClick={() => setPasswordVisible(!isPasswordVisible)}>
										{isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
									</IconButton>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
			</Box>

			<Button
				variant="contained"
				sx={{
					width: 100,
				}}
				onClick={handleLogin}
			>
				{i18n('auth.login')}
			</Button>
		</Paper>
	);
};
