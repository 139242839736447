import { Box, FormControl, FormHelperText } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import MuiRichTextEditor from 'mui-rte';
import React, { useEffect, useRef } from 'react';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import type { TMUIRichTextEditorRef } from 'mui-rte';
import type { I18NKey } from '../i18n';

export type GetValuesFn = () => Promise<[string, string]>;

export interface RichTextEditorProps {
	id: string;
	label: I18NKey;
	defaultValue: string;
	isDisabled?: boolean;
	errorMessage?: I18NKey;
	getValuesRef: React.MutableRefObject<GetValuesFn | null>;
	onFocus?: () => void;
}

export const RichTextEditor = ({
	id,
	label,
	defaultValue,
	isDisabled,
	errorMessage,
	getValuesRef,
	onFocus,
}: RichTextEditorProps): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();

	const editorRef = useRef<TMUIRichTextEditorRef>(null);
	const resolveRef = useRef<((values: [string, string]) => void) | null>(null);

	const getValues: GetValuesFn = () => {
		return new Promise((resolve) => {
			if (editorRef.current) {
				resolveRef.current = resolve;
				editorRef.current.save();
			} else {
				resolve(['', '']);
			}
		});
	};

	useEffect(() => {
		if (getValuesRef) {
			getValuesRef.current = getValues;
		}
	}, [getValuesRef]);

	return (
		<FormControl
			sx={{
				width: 1,
				backgroundColor: 'inherit',
				backgroundImage: 'inherit',
			}}
		>
			<Box
				sx={{
					position: 'relative',
					minHeight: 250,
					maxHeight: 250,
					overflow: 'auto',
					px: 2,
					backgroundColor: 'inherit',
					backgroundImage: 'inherit',
					border: '1px solid',
					borderColor: ({ palette }) =>
						errorMessage ? palette.error.main : palette.grey[palette.mode === 'light' ? 400 : 700],
					borderRadius: 1,

					...(errorMessage
						? {}
						: {
								'&:hover': {
									borderColor: 'text.primary',
								},
						  }),
				}}
			>
				<MuiRichTextEditor
					id={id}
					label={`${i18n(label)} *`}
					defaultValue={defaultValue}
					readOnly={isDisabled}
					controls={[
						'title',
						'bold',
						'italic',
						'underline',
						'strikethrough',
						'highlight',
						'numberList',
						'bulletList',
						'quote',
						'code',
						'undo',
						'redo',
					]}
					toolbarButtonSize={isMobile ? 'small' : 'medium'}
					ref={editorRef}
					onFocus={onFocus}
					onSave={(descriptionRaw) => {
						if (resolveRef.current) {
							resolveRef.current([
								descriptionRaw.trim(),
								draftToHtml(JSON.parse(descriptionRaw)).trim(),
							]);
						}
					}}
				/>
			</Box>
			<FormHelperText error={!!errorMessage}>
				{errorMessage ? i18n(errorMessage) : ''}
			</FormHelperText>
		</FormControl>
	);
};
