export interface StorageItem<T> {
	value: T;
	updatedAt: number;
}

export const Storage = {
	local: {
		set: <T>(key: string, value: T): void => {
			localStorage.setItem(
				key,
				JSON.stringify({
					value,
					updatedAt: Date.now(),
				})
			);
		},

		get: <T>(key: string, expiryInSeconds = Number.POSITIVE_INFINITY): T | undefined => {
			const item = JSON.parse(localStorage.getItem(key) || '{ "updatedAt": 0 }') as StorageItem<T>;

			if (item.updatedAt && Date.now() - item.updatedAt > expiryInSeconds * 1e3) {
				item.value = undefined as unknown as T;
				localStorage.removeItem(key);
			}

			return item.value;
		},

		remove: (key: string): void => {
			localStorage.removeItem(key);
		},
	},
};
