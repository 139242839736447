/**
 * Border animation code taken from https://stackoverflow.com/a/52963366
 */

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Chip, Input, Typography, useTheme as useMuiTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useCallback, useRef, useState } from 'react';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';

export interface FileDropAreaProps {
	isDisabled?: boolean;
	files: File[];
	setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const FileDropArea = ({ isDisabled, files, setFiles }: FileDropAreaProps): JSX.Element => {
	const { palette } = useMuiTheme();

	const i18n = useI18N();
	const { snackbar } = useRedux();

	const [isDragging, setDragging] = useState(false);

	const fileInputRef = useRef<HTMLDivElement>(null);

	const color = palette.mode === 'light' ? grey[200] : grey[800];
	const contrastColor = palette.getContrastText(color);

	const handleDragEnter = () => {
		if (!isDisabled) {
			setDragging(true);
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDragLeave = () => {
		if (!isDisabled) {
			setDragging(false);
		}
	};

	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();

			if (isDisabled) {
				setDragging(false);

				return;
			}

			const newFiles = [];

			for (const file of Array.from(e.dataTransfer.files)) {
				if (files.some((currentFile) => currentFile.name === file.name)) {
					snackbar.show({ status: 'error', message: 'tickets.form.errors.attachments.duplicate' });
					setDragging(false);

					return;
				}

				newFiles.push(file);
			}

			setFiles([...files, ...newFiles]);
			setDragging(false);
		},
		[files]
	);

	const handleClick = () => {
		if (!isDisabled && fileInputRef.current) {
			fileInputRef.current.dispatchEvent(new MouseEvent('click'));
		}
	};

	const handleFileInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.files) {
				const newFiles = [];

				for (const file of Array.from(e.target.files)) {
					if (files.some((currentFile) => currentFile.name === file.name)) {
						snackbar.show({
							status: 'error',
							message: 'tickets.form.errors.attachments.duplicate',
						});

						return;
					}

					newFiles.push(file);
				}

				setFiles([...files, ...newFiles]);
			}
		},
		[files]
	);

	const handleFileDelete = (file: File) => {
		setFiles((prevFiles) => prevFiles.filter((currentFile) => currentFile.name !== file.name));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'flex-start',
				gap: 2,
				width: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 2,
					width: 1,
					height: 200,
					p: 2,
					backgroundColor: color,
					backgroundImage: [
						`linear-gradient(90deg, ${contrastColor} 50%, transparent 50%)`,
						`linear-gradient(90deg, ${contrastColor} 50%, transparent 50%)`,
						`linear-gradient(0deg, ${contrastColor} 50%, transparent 50%)`,
						`linear-gradient(0deg, ${contrastColor} 50%, transparent 50%)`,
					].join(', '),
					backgroundPosition: 'left top, right bottom, left bottom, right top',
					backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
					backgroundSize: '15px 2px, 15px 2px, 2px 15px, 2px 15px',
					color: contrastColor,
					borderRadius: 1,
					cursor: isDisabled ? 'default' : 'pointer',
					opacity: isDisabled ? 0.5 : 1,
					animation: 'slide-border 0.5s infinite linear',
					animationPlayState: isDragging ? 'running' : 'paused',

					'@keyframes slide-border': {
						'0%': {
							backgroundPosition: 'left top, right bottom, left bottom, right top',
						},

						'100%': {
							backgroundPosition:
								'left 15px top, right 15px bottom, left bottom 15px, right top 15px',
						},
					},

					'*': {
						pointerEvents: 'none',
					},
				}}
				onDragEnter={handleDragEnter}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				onClick={handleClick}
			>
				<Typography textAlign="center">{i18n('tickets.form.attachments.dragAndDrop')}</Typography>

				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<CloudUploadIcon
						fontSize="large"
						sx={{
							position: 'absolute',
							animation: 'fluctuate 1s infinite linear',
							animationPlayState: isDragging ? 'running' : 'paused',

							'@keyframes fluctuate': {
								'0%, 100%': {
									top: 0,
								},

								'50%': {
									top: ({ spacing }) => spacing(2),
								},
							},
						}}
					/>
				</Box>
			</Box>

			<Input
				type="file"
				inputProps={{
					multiple: true,
				}}
				sx={{
					display: 'none',
				}}
				inputRef={fileInputRef}
				onChange={handleFileInputChange}
			/>

			{files.map((file) => (
				<Chip
					key={file.name}
					label={file.name}
					icon={<AttachFileIcon />}
					disabled={isDisabled}
					sx={{
						width: 'unset',
						maxWidth: 300,
					}}
					onDelete={() => handleFileDelete(file)}
				/>
			))}
		</Box>
	);
};
