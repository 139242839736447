import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContainer } from '../components/AppContainer';
import { useRedux } from '../redux';
import { Auth } from '../services/Auth';

export type SSOParams = {
	token: string;
};

export const SSO = (): JSX.Element => {
	const params = useParams<SSOParams>();

	const { snackbar, spinner } = useRedux();

	useEffect(() => {
		const handleSSO = async () => {
			if (!params.token) {
				return;
			}

			spinner.show();

			try {
				await Auth.sso(params.token);

				spinner.hide();
				snackbar.show({ status: 'success', message: 'auth.success.login' });
			} catch (err) {
				spinner.hide();
				snackbar.show({ status: 'error', message: 'auth.errors.login' });
			}
		};

		void handleSSO();
	}, [params.token]);

	return <AppContainer>{null}</AppContainer>;
};
