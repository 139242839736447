import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';

export interface AuthState {
	isLoggingIn: boolean;
	isLoggedIn: boolean;
	user: AuthUser | null;
	company: AuthCompany | null;
}

export interface AuthPayload {
	user: AuthUser;
	company: AuthCompany;
}

export interface AuthUser {
	uid: string;
	cpf: string;
	name: string;
	email: string;
	phone: string;
	hasLicense: Record<string, boolean>;
}

export interface AuthCompany {
	uid: string;
	cnpj: string;
	name: string;
	fantasyName: string;
}

const initialState: AuthState = {
	isLoggingIn: true,
	isLoggedIn: false,
	user: {
		uid: '',
		cpf: '',
		name: '',
		email: '',
		phone: '',
		hasLicense: {},
	},
	company: {
		uid: '',
		cnpj: '',
		name: '',
		fantasyName: '',
	},
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: (state, { payload }: PayloadAction<AuthPayload>) => {
			state.isLoggingIn = false;
			state.isLoggedIn = true;
			state.user = payload.user;
			state.company = payload.company;
		},

		logout: (state) => {
			state.isLoggingIn = false;
			state.isLoggedIn = false;
			state.user = null;
			state.company = null;
		},
	},
});

export const useAuth = () => useSelector<RootState, AuthState>((state) => state.auth);
