import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useRedux } from '../redux';
import { appcenterFirestore, inboxAuth } from '../services/firebase';

export interface AuthContainerProps {
	children: React.ReactNode;
}

export const AuthContainer = ({ children }: AuthContainerProps): JSX.Element => {
	const { auth, spinner } = useRedux();

	useEffect(() => {
		spinner.show();
	}, []);

	useEffect(() => {
		return onAuthStateChanged(inboxAuth, async (user) => {
			if (user?.uid) {
				const userDoc = await getDoc(doc(appcenterFirestore, `/user/${user.uid}`));
				const userData = userDoc.data();

				let companyData;

				if (userData?.companies[0]) {
					const companyDoc = await getDoc(
						doc(appcenterFirestore, `/company/${userData.companies[0]}`)
					);
					companyData = companyDoc.data();
				}

				const hasLicense: Record<string, boolean> = {};

				if (userData && companyData) {
					for (const [appId, app] of Object.entries(userData.applications) as [
						string,
						Record<string, Record<string, boolean>>
					][]) {
						if (app.companies[companyData.uid]) {
							hasLicense[appId] = true;
						}
					}
				}

				spinner.hide();
				auth.login({
					user: {
						uid: user.uid,
						cpf: userData?.taxDocument ?? '',
						name: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`,
						email: userData?.email ?? '',
						phone: `${userData?.phoneCountry ?? ''} (${userData?.phoneArea ?? ''}) ${
							userData?.phone ?? ''
						}`,
						hasLicense,
					},
					company: {
						uid: companyData?.uid ?? '',
						cnpj: companyData?.identificationNumber ?? '',
						name: companyData?.name ?? '',
						fantasyName: companyData?.fantasyName ?? '',
					},
				});
			} else {
				spinner.hide();
				auth.logout();
			}
		});
	}, []);

	return <>{children}</>;
};
