import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useIsMobile } from '../hooks';
import { locales, useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useTheme } from '../redux/slices/theme';
import { StyledMenu } from './StyledMenu';
import type { Language } from '../i18n';

export const ChangeLanguageButton = (): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { theme } = useRedux();
	const { language } = useTheme();

	const [isMenuOpen, setMenuOpen] = useState(false);

	const anchorRef = useRef<HTMLButtonElement>(null);

	const handleClick = (currentLanguage: Language) => {
		theme.update({ language: currentLanguage });
		setMenuOpen(false);
	};

	return (
		<>
			<Tooltip title={i18n('theme.language.change')}>
				<IconButton
					size={isMobile ? 'small' : 'medium'}
					color="inherit"
					ref={anchorRef}
					onClick={() => setMenuOpen(!isMenuOpen)}
				>
					<LanguageIcon />
				</IconButton>
			</Tooltip>

			<StyledMenu open={isMenuOpen} anchorEl={anchorRef.current} onClose={() => setMenuOpen(false)}>
				{Object.entries(locales).map(([currentLanguage, currentLanguageDetails]) => (
					<MenuItem
						key={currentLanguage}
						selected={currentLanguage === language}
						onClick={() => handleClick(currentLanguage as Language)}
					>
						{currentLanguageDetails.name}
					</MenuItem>
				))}
			</StyledMenu>
		</>
	);
};
