import React from 'react';
import { useParams } from 'react-router-dom';
import { AppContainer } from '../components/AppContainer';
import { TicketView } from '../components/TicketView';
import { useIsMobile } from '../hooks';

export type TicketParams = {
	id: string;
};

export const ViewTicket = () => {
	const params = useParams<TicketParams>();

	const isMobile = useIsMobile();

	return (
		<AppContainer
			sx={{
				p: isMobile ? 2 : 4,
			}}
		>
			<TicketView id={params.id} />
		</AppContainer>
	);
};
