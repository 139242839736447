import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { locales } from '../i18n';
import { useTheme } from '../redux/slices/theme';
import type { CSSObject } from '@mui/material';

declare module '@mui/material/styles' {
	interface Components {
		MUIRichTextEditor?: {
			styleOverrides?: Record<string, CSSObject>;
		};
	}
}

export interface ThemeContainerProps {
	children: React.ReactNode;
}

export const ThemeContainer = ({ children }: ThemeContainerProps): JSX.Element => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	const { mode, language } = useTheme();

	const systemMode = useMemo(() => {
		return prefersDarkMode ? 'dark' : 'light';
	}, [prefersDarkMode]);

	const theme = useMemo(() => {
		const globalTheme = createTheme(
			{
				palette: {
					mode: mode === 'system' ? systemMode : mode,
				},
			},
			locales[language].locale
		);
		return createTheme(
			{
				components: {
					MUIRichTextEditor: {
						styleOverrides: {
							root: {
								backgroundColor: 'inherit',
								backgroundImage: 'inherit',
							},
							container: {
								margin: 0,
								backgroundColor: 'inherit',
								backgroundImage: 'inherit',
							},
							toolbar: {
								position: 'sticky',
								top: 0,
								paddingTop: globalTheme.spacing(0.5),
								paddingBottom: globalTheme.spacing(0.5),
								backgroundColor: 'inherit',
								backgroundImage: 'inherit',
								borderBottom: `1px solid ${globalTheme.palette.divider}`,
							},
						},
					},
				},
			},
			globalTheme
		);
	}, [mode, systemMode, language]);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};
