import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';

export interface DialogState {
	isOpen: boolean;
	action: DialogAction;
	data: Record<string, unknown>;
}

export interface DialogPayload {
	action: DialogAction;
}

export interface DialogUpdatePayload {
	data: Record<string, unknown>;
}

export type DialogAction = '' | 'cancel-ticket' | 'close-ticket';

const initialState: DialogState = {
	isOpen: false,
	action: '',
	data: {},
};

export const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		show: (state, { payload }: PayloadAction<DialogPayload>) => {
			state.isOpen = true;
			state.action = payload.action;
		},

		hide: (state) => {
			state.isOpen = false;
		},

		update: (state, { payload }: PayloadAction<DialogUpdatePayload>) => {
			state.data = payload.data;
		},
	},
});

export const useDialog = () => useSelector<RootState, DialogState>((state) => state.dialog);
