import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';
import type { StorageItem } from '../../services/Storage';

export interface TicketsState {
	apiData: TicketsApiData;
	tickets: StorageItem<TicketData[]>;
	ticketsComments: Record<string, StorageItem<TicketCommentData[]>>;
}

export interface TicketsPayload {
	apiData?: Partial<TicketsApiData>;
	tickets?: StorageItem<TicketData[]>;
	ticketsComments?: Record<string, StorageItem<TicketCommentData[]>>;
}

export interface TicketsApiData {
	groups: TicketsApiDataItem[];
	types: TicketsApiDataItem[];
	states: TicketsApiDataItem[];
	users: TicketsApiDataItem[];
}

export interface TicketsApiDataItem {
	id: string;
	name: string;
}

export interface TicketsApiCustomFieldData {
	name: string;
	description: string;
	required: boolean;
}

export interface TicketData {
	id: string;
	number: string;
	subject: string;
	description: string;
	group: TicketsApiDataItem;
	type: TicketsApiDataItem;
	state: TicketsApiDataItem;
	deadline: number;
	responsible: TicketsApiDataItem | null;
	creationDate: number;
	creationUser: TicketsApiDataItem;
	lastChangeDate: number;
	lastChangeUser: TicketsApiDataItem;
	endDate: number | null;
}

export interface TicketCustomFieldData {
	question: string;
	answer: string;
	defaultForm: boolean;
}

export interface TicketCommentData {
	id: string;
	date: number;
	user: TicketsApiDataItem;
	content: string;
	public: boolean;
}

export interface TicketAttachmentData {
	id: string;
	name: string;
	url: string;
	mimeType: string;
	length: number;
	humanReadableLength: string;
	public: boolean;
}

export type TicketProduct = typeof TICKET_PRODUCTS[number];

export type TicketCategory = typeof TICKET_CATEGORIES[number];

export type TicketEnvironment = typeof TICKET_ENVIRONMENTS[number];

export type TicketOperationStatus = typeof TICKET_OPERATION_STATUSES[number];

export type TicketImpactedUsers = typeof TICKET_IMPACTED_USERS[number];

export type TicketFinancialImpact = typeof TICKET_FINANCIAL_IMPACTS[number];

export type TicketPriority = typeof TICKET_PRIORITIES[number];

export const TICKET_PRODUCTS = [
	'AppCenter',
	'CRM',
	'Dok+',
	'DokDrive',
	'EditFy',
	'Indexia',
	'MultiScan',
	'Solicitações para Minha Franquia',
	'Taugor Interno',
	'Yopen',
	'Workflux',
] as const;

export const TICKET_CATEGORIES = [
	'Admin - Contrato e afins',
	'Admin - Multa Contratual',
	'Admin - Rescisão de Contrato',
	'API',
	'Com - Duvidas Comerciais',
	'Com - R.O - Registro de Oportunidade',
	'Finan - Novo Boleto',
	'Finan - Solicitação de Compra de Material',
	'Sup Téc - E-mail',
	'Sup Tec - Criação de Ambiente Novo',
	'Sup Tec - Duvidas',
	'Sup Tec - Especialista',
	'Mkt - Alteração de Site',
	'Mkt - Homologação Franquia - Videos e Fotos',
	'Mkt - Material Digital',
	'Mkt - Material Impresso',
	'Mkt - Site',
] as const;

export const TICKET_ENVIRONMENTS = [
	'Ambiente de produção - Cliente Ativo/Licença',
	'Dados/ambiente de teste - Somente Teste',
] as const;

export const TICKET_OPERATION_STATUSES = [
	'Operação Parada',
	'Operação consegue trabalhar',
] as const;

export const TICKET_IMPACTED_USERS = [
	'Apenas 1',
	'1 a 10 usuários',
	'11 a 30 usuários',
	'31 a 50 usuários',
	'mais de 50 usuários',
] as const;

export const TICKET_FINANCIAL_IMPACTS = [
	'Sem perda Financeira',
	'Com Perda financeira - Cliente Ativo ou Lead',
] as const;

export const TICKET_PRIORITIES = ['Baixa', 'Média', 'Alta'] as const;

const initialState: TicketsState = {
	apiData: {
		groups: [],
		types: [],
		states: [],
		users: [],
	},
	tickets: {
		value: [],
		updatedAt: 0,
	},
	ticketsComments: {},
};

export const ticketsSlice = createSlice({
	name: 'tickets',
	initialState,
	reducers: {
		update: (state, { payload }: PayloadAction<TicketsPayload>) => {
			if (payload.apiData) {
				if (payload.apiData.groups) {
					state.apiData.groups = payload.apiData.groups;
				}

				if (payload.apiData.types) {
					state.apiData.types = payload.apiData.types;
				}

				if (payload.apiData.states) {
					state.apiData.states = payload.apiData.states;
				}

				if (payload.apiData.users) {
					state.apiData.users = payload.apiData.users;
				}
			}

			if (payload.tickets) {
				state.tickets = payload.tickets;
			}

			if (payload.ticketsComments) {
				state.ticketsComments = payload.ticketsComments;
			}
		},
	},
});

export const useTickets = () => useSelector<RootState, TicketsState>((state) => state.tickets);
