import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './screens/Home';
import { Login } from './screens/Login';
import { NewTicket } from './screens/NewTicket';
import { SSO } from './screens/SSO';
import { ViewTicket } from './screens/ViewTicket';

export const App = (): JSX.Element => {
	return (
		<Router>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/sso/:token" element={<SSO />} />
				<Route path="/tickets/new" element={<NewTicket />} />
				<Route path="/tickets/:id" element={<ViewTicket />} />
				<Route path="/" element={<Home />} />
			</Routes>
		</Router>
	);
};
