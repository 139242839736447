import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const appcenterApp = initializeApp(
	{
		apiKey: 'AIzaSyAuTijrxdZUV7VlhvT_iZYwCsKgYBA5JME',
		authDomain: 'taugor-appcenter.firebaseapp.com',
		databaseURL: 'https://taugor-appcenter.firebaseio.com',
		projectId: 'taugor-appcenter',
		storageBucket: 'taugor-appcenter.appspot.com',
		messagingSenderId: '970445245374',
		appId: '1:970445245374:web:c6ac862d6a43e4e68547ef',
		measurementId: 'G-BTSY0HJ1VY',
	},
	'appcenter'
);

export const appcenterAuth = getAuth(appcenterApp);

export const appcenterFirestore = getFirestore(appcenterApp);

const inboxApp = initializeApp(
	{
		apiKey: 'AIzaSyBJBCl3_Dy7b3hOMVvuDeGjaaHFwanbPE4',
		authDomain: 'inbox-ticket.firebaseapp.com',
		projectId: 'inbox-ticket',
		storageBucket: 'inbox-ticket.appspot.com',
		messagingSenderId: '397738158728',
		appId: '1:397738158728:web:22fa9220ed90b09ee20d3d',
		measurementId: 'G-C8V7J2L0BN',
	},
	'inbox'
);

export const inboxAuth = getAuth(inboxApp);

setPersistence(appcenterAuth, browserLocalPersistence);
setPersistence(inboxAuth, browserLocalPersistence);
