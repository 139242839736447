import axios from 'axios';
import { RequestError } from './RequestError';
import type { AxiosError, AxiosResponse } from 'axios';
import type {
	TicketData,
	TicketCategory,
	TicketCommentData,
	TicketCustomFieldData,
	TicketEnvironment,
	TicketFinancialImpact,
	TicketImpactedUsers,
	TicketOperationStatus,
	TicketPriority,
	TicketProduct,
	TicketsApiDataItem,
	TicketsApiCustomFieldData,
	TicketAttachmentData,
} from '../redux/slices/tickets';

export interface AuthLoginResponse {
	tokens: {
		appcenterCustomer: string;
		inboxticket: string;
	};
}

export interface AuthSSOResponse {
	uid: string;
}

export interface TicketsCreateParams {
	title: string;
	description: string;
	shortDescription: string;
	group: string;
	type: string;
	state: string;
	email: string;
	name: string;
	product: TicketProduct;
	category: TicketCategory;
	cnpj: string;
	phone: string;
	clientPhone?: string;
	environment: TicketEnvironment;
	operationStatus: TicketOperationStatus;
	impactedUsers: TicketImpactedUsers;
	financialImpact: TicketFinancialImpact;
	priority: TicketPriority;
}

export interface TicketsUpdateParams {
	title: string;
	description: string;
	group: string;
	type: string;
	state: string;
}

export interface TicketsCreateResponse {
	ticketId: string;
}

export interface TicketsListParams {
	email: string;
	phone?: string;
}

const isLocal = window.location.hostname === 'localhost';

const appcenterApi = axios.create({
	baseURL: isLocal
		? 'http://localhost:5001/taugor-appcenter/us-central1/customer_api/'
		: 'https://us-central1-taugor-appcenter.cloudfunctions.net/customer_api/',
	timeout: 240000,
});

export const HIPLATFORM_ACCOUNT_MAIL = 'f9758791-490b-4994-9ef9-b16ba938962c';
export const HIPLATFORM_USER = '3377be3e-9a31-471e-9626-2bef65054174';
export const HIPLATFORM_MAIL_USER = 'ce99f3f4-b039-4c54-bddb-b31350a84a10';

const hiplatformApi = axios.create({
	baseURL: 'https://api.directtalk.com.br/1.5/ticket',
	auth: {
		username: 'cptg87c5ca86-9a54-458c-a997-c5bbca57b8c6',
		password: 'b3keoy09035zge4ex7k9',
	},
	timeout: 240000,
});

const handleRequest = async <T>(request: Promise<AxiosResponse<T>>): Promise<T> => {
	try {
		const { data } = await request;

		return data;
	} catch (err) {
		if (err instanceof Error) {
			const { data } = (err as AxiosError).response ?? {};

			throw new RequestError(err.message, data);
		}

		throw err;
	}
};

export const Api = {
	auth: {
		login: async (userOrToken: string, password: string | boolean): Promise<AuthLoginResponse> => {
			return handleRequest(
				appcenterApi.post('/authentication/inboxticket', {
					user: userOrToken,
					password,
					all: true,
				})
			);
		},

		loginWithSSOToken: async (token: string): Promise<AuthLoginResponse> => {
			return Api.auth.login(token, false);
		},

		getSSOToken: async (
			userUid: string,
			companyUid: string,
			appId: string
		): Promise<AuthSSOResponse> => {
			return handleRequest(
				appcenterApi.post(`/authentication/sso/${companyUid}/${appId}/${userUid}`)
			);
		},
	},

	tickets: {
		listGroups: async (): Promise<TicketsApiDataItem[]> => {
			return handleRequest(hiplatformApi.get('/groups'));
		},

		listTypes: async (): Promise<TicketsApiDataItem[]> => {
			return handleRequest(hiplatformApi.get('/types'));
		},

		listStates: async (): Promise<TicketsApiDataItem[]> => {
			return handleRequest(hiplatformApi.get('/states'));
		},

		listUsers: async (): Promise<TicketsApiDataItem[]> => {
			return handleRequest(hiplatformApi.get('/users'));
		},

		listCustomFields: async (): Promise<TicketsApiCustomFieldData[]> => {
			return handleRequest(hiplatformApi.get(`/customforms`));
		},

		create: async (params: TicketsCreateParams): Promise<TicketsCreateResponse> => {
			return handleRequest(
				hiplatformApi.post('/tickets/mail', {
					subject: params.title,
					description: params.description,
					group: params.group,
					type: params.type,
					state: params.state,
					emailConsumer: params.email,
					nameConsumer: params.name,
					accountMail: HIPLATFORM_ACCOUNT_MAIL,
					identifications: [
						params.shortDescription
							? {
									key: 'problema_em_uma_frase',
									value: params.shortDescription,
							  }
							: null,
						params.email
							? {
									key: 'solicitantecliente_-_email_do_solicitante',
									value: params.email,
							  }
							: null,
						params.product
							? {
									key: 'produto_ou_servicos',
									value: params.product,
							  }
							: null,
						params.category
							? {
									key: 'categoria_de_assunto',
									value: params.category,
							  }
							: null,
						params.cnpj
							? {
									key: 'cnpj',
									value: params.cnpj,
							  }
							: null,
						params.name
							? {
									key: 'nome_do_contato',
									value: params.name,
							  }
							: null,
						params.email
							? {
									key: 'e-mail_do_contato',
									value: params.email,
							  }
							: null,
						params.phone
							? {
									key: 'telefone_do_contato',
									value: params.phone,
							  }
							: null,
						params.clientPhone
							? {
									key: 'telefone_do_cliente',
									value: params.clientPhone,
							  }
							: null,
						params.environment
							? {
									key: 'informacoes_do_ambiente',
									value: params.environment,
							  }
							: null,
						params.operationStatus
							? {
									key: 'sua_operacao_esta_parada',
									value: params.operationStatus,
							  }
							: null,
						params.impactedUsers
							? {
									key: 'usuarios_impactados',
									value: params.impactedUsers,
							  }
							: null,
						params.financialImpact
							? {
									key: 'impacto_financeiro',
									value: params.financialImpact,
							  }
							: null,
						params.priority
							? {
									key: 'prioridade',
									value: params.priority,
							  }
							: null,
					].filter((identification) => !!identification),
				})
			);
		},

		update: async (id: string, params: TicketsUpdateParams): Promise<void> => {
			return handleRequest(
				hiplatformApi.put(`/tickets/${id}`, {
					user: HIPLATFORM_USER,
					subject: params.title,
					description: params.description,
					group: params.group,
					type: params.type,
					state: params.state,
				})
			);
		},

		list: async ({ email, phone }: TicketsListParams): Promise<TicketData[]> => {
			const byEmail = handleRequest<TicketData[]>(
				hiplatformApi.get('/tickets', {
					params: {
						sort: 'number',
						desc: true,
						customformsdata: `solicitantecliente_-_email_do_solicitante|${email}`,
					},
				})
			);
			const byPhone =
				phone && phone !== '+55 ()'
					? handleRequest<TicketData[]>(
							hiplatformApi.get('/tickets', {
								params: {
									sort: 'number',
									desc: true,
									customformsdata: `telefone_do_contato|${phone}`,
								},
							})
					  )
					: Promise.resolve([]);

			const datas = await Promise.all([byEmail, byPhone]);

			return datas.flat();
		},

		get: async (id: string): Promise<TicketData> => {
			return handleRequest(hiplatformApi.get(`/tickets/${id}`));
		},

		getCustomFields: async (id: string): Promise<TicketCustomFieldData[]> => {
			return handleRequest(hiplatformApi.get(`/tickets/${id}/forms`));
		},

		getAttachments: async (id: string): Promise<TicketAttachmentData[]> => {
			return handleRequest(hiplatformApi.get(`/tickets/${id}/attachments`));
		},

		getComments: async (id: string): Promise<TicketCommentData[]> => {
			return handleRequest(hiplatformApi.get(`/tickets/${id}/comments`));
		},

		close: async (id: string): Promise<void> => {
			return handleRequest(
				hiplatformApi.patch(`/tickets/${id}?state=close`, {
					user: HIPLATFORM_USER,
				})
			);
		},

		reopen: async (id: string): Promise<void> => {
			return handleRequest(
				hiplatformApi.patch(`/tickets/${id}?state=reopen`, {
					user: HIPLATFORM_USER,
				})
			);
		},

		downloadAttachment: async (url: string): Promise<Blob> => {
			return handleRequest(
				hiplatformApi.get(url, {
					responseType: 'blob',
				})
			);
		},

		addAttachment: async (id: string, file: File): Promise<void> => {
			const formData = new FormData();
			formData.append('filename', file.name);
			formData.append('file', file);

			return handleRequest(
				hiplatformApi.post(
					`/tickets/${id}/attachments?userid=${HIPLATFORM_USER}&publicAttach=true`,
					formData
				)
			);
		},

		addComment: async (id: string, comment: string): Promise<void> => {
			return handleRequest(
				hiplatformApi.post(`/tickets/${id}/comments`, {
					creator: HIPLATFORM_USER,
					content: comment.replace(/<[^>]*>/g, ''),
					public: true,
				})
			);
		},
	},
};
