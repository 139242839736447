import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useSpinner } from '../redux/slices/spinner';

export const Spinner = (): JSX.Element => {
	const { isLoading } = useSpinner();

	return (
		<Backdrop
			open={isLoading}
			sx={{
				zIndex: ({ zIndex }) => zIndex.drawer + 1,
				color: 'white',
			}}
		>
			<CircularProgress color="inherit" size="5rem" />
		</Backdrop>
	);
};
