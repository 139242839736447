import React from 'react';
import { AppContainer } from '../components/AppContainer';
import { TicketForm } from '../components/TicketForm';
import { useIsMobile } from '../hooks';

export const NewTicket = (): JSX.Element => {
	const isMobile = useIsMobile();

	return (
		<AppContainer
			sx={{
				p: isMobile ? 2 : 4,
			}}
		>
			<TicketForm />
		</AppContainer>
	);
};
