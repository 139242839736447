import { Box, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useAuth } from '../redux/slices/auth';
import { useTheme } from '../redux/slices/theme';
import { HIPLATFORM_MAIL_USER, HIPLATFORM_USER } from '../services/Api';
import type { TicketCommentData } from '../redux/slices/tickets';

export interface TicketCommentProps {
	comment: TicketCommentData | null;
}

export const TicketComment = ({ comment }: TicketCommentProps): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { user } = useAuth();
	const { language } = useTheme();

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				width: 1,
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					p: 1,
					backgroundColor: ({ palette }) => palette.info.main,
					color: ({ palette }) => palette.getContrastText(palette.info.main),
				}}
			>
				<Typography variant="caption" noWrap>
					{comment ? (
						<>
							<Box
								component="span"
								sx={{
									fontWeight: 'bold',
								}}
							>
								{new Intl.DateTimeFormat(language, {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									hour12: false,
									minute: '2-digit',
									second: '2-digit',
								}).format(new Date(comment.date * 1e3))}
							</Box>{' '}
							{i18n('tickets.view.by')}{' '}
							<Box
								component="span"
								sx={{
									fontWeight: 'bold',
								}}
							>
								{comment.user.id === HIPLATFORM_USER || comment.user.id === HIPLATFORM_MAIL_USER
									? `${user?.name ?? '-'}`
									: comment.user.name}
							</Box>
						</>
					) : (
						<Skeleton variant="text" width={150} />
					)}
				</Typography>
			</Box>

			{comment ? (
				<Box
					dangerouslySetInnerHTML={{
						__html: comment.content,
					}}
					sx={{
						p: isMobile ? 1 : 2,
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-all',
						lineHeight: 'normal',

						'*': {
							margin: 0,
						},
					}}
				/>
			) : (
				<Box
					sx={{
						p: isMobile ? 1 : 2,
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-all',
						lineHeight: 'normal',

						'*': {
							margin: 0,
						},
					}}
				>
					<Skeleton variant="text" width={isMobile ? 250 : 500} />
					<Skeleton variant="text" width={isMobile ? 150 : 300} />
					<Skeleton variant="text" width={isMobile ? 200 : 400} />
					<Skeleton variant="text" width={isMobile ? 100 : 200} />
					<Skeleton variant="text" width={isMobile ? 50 : 100} />
				</Box>
			)}
		</Paper>
	);
};
