import {
	Box,
	Button,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useDialog } from '../redux/slices/dialog';
import type { I18NKey } from '../i18n';

export interface CloseTicketDialogErrors {
	reason: I18NKey;
	reasonDetails: I18NKey;
}

export const CloseTicketDialog = (): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { dialog, snackbar } = useRedux();
	const { isOpen, action } = useDialog();

	const [reason, setReason] = useState('');
	const [reasonDetails, setReasonDetails] = useState('');
	const [errors, setErrors] = useState<CloseTicketDialogErrors>({
		reason: '',
		reasonDetails: '',
	});

	const handleCancel = () => {
		dialog.hide();
	};

	const handleOk = () => {
		const newErrors: CloseTicketDialogErrors = {
			reason: '',
			reasonDetails: '',
		};
		let hasErrors = false;

		if (!action) {
			snackbar.show({ status: 'error', message: 'dialog.errors.ok' });

			return;
		}

		if (!reason) {
			newErrors.reason = `dialogs.${action}.errors.reason.empty` as const;
			hasErrors = true;
		}

		if (reasonDetails) {
			if (reasonDetails.length < 15) {
				newErrors.reasonDetails = `dialogs.${action}.errors.reasonDetails.min15` as const;
				hasErrors = true;
			}
		} else {
			newErrors.reasonDetails = `dialogs.${action}.errors.reasonDetails.empty` as const;
			hasErrors = true;
		}

		if (hasErrors) {
			setErrors(newErrors);

			return;
		}

		dialog.hide();
		dialog.update({
			data: {
				reason,
				reasonDetails,
			},
		});
	};

	if (!action) {
		return <></>;
	}

	const reasons = [];

	// eslint-disable-next-line no-constant-condition
	for (let i = 1; true; i++) {
		const message = i18n(`dialogs.${action}.reason.${i}` as I18NKey);

		if (message) {
			reasons.push(message);
		} else {
			break;
		}
	}

	return (
		<MuiDialog open={isOpen}>
			<DialogTitle>{i18n(`dialogs.${action}.title` as const)}</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 2,
						minWidth: isMobile ? 200 : 400,
						py: 2,

						'> *': {
							width: 1,
						},
					}}
				>
					<FormControl>
						<InputLabel required error={!!errors.reason}>
							{i18n(`dialogs.${action}.reason` as const)}
						</InputLabel>

						<Select
							id="reason"
							label={i18n(`dialogs.${action}.reason` as const)}
							value={reason}
							required
							error={!!errors.reason}
							onChange={(e) => {
								setReason(e.target.value);

								if (errors.reason) {
									setErrors((prevErrors) => ({
										...prevErrors,
										reason: '',
									}));
								}
							}}
						>
							{reasons.map((reason) => (
								<MenuItem key={reason} value={reason}>
									{reason}
								</MenuItem>
							))}
						</Select>

						<FormHelperText error={!!errors.reason}>
							{errors.reason ? i18n(errors.reason) : ''}
						</FormHelperText>
					</FormControl>

					<TextField
						id="reasonDetails"
						label={i18n(`dialogs.${action}.reasonDetails` as const)}
						value={reasonDetails}
						required
						multiline
						rows={5}
						error={!!errors.reasonDetails}
						helperText={errors.reasonDetails ? i18n(errors.reasonDetails) : ''}
						onChange={(e) => {
							setReasonDetails(e.target.value);

							if (errors.reasonDetails) {
								setErrors((prevErrors) => ({
									...prevErrors,
									reasonDetails: '',
								}));
							}
						}}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button size={isMobile ? 'small' : 'medium'} onClick={handleCancel}>
					{i18n(`dialogs.${action}.cancel` as const)}
				</Button>
				<Button size={isMobile ? 'small' : 'medium'} onClick={handleOk}>
					{i18n(`dialogs.${action}.ok` as const)}
				</Button>
			</DialogActions>
		</MuiDialog>
	);
};
