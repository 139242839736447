import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import StarIcon from '@mui/icons-material/Star';
import { Box, Chip, Typography, useTheme as useMuiTheme } from '@mui/material';
import React from 'react';
import type { TicketData } from '../redux/slices/tickets';

export interface TicketStatusProps {
	ticket: TicketData;
	isChip?: boolean;
}

export const TicketStatus = React.forwardRef(
	(props: TicketStatusProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
		const { ticket, isChip, ...otherProps } = props;

		const { palette } = useMuiTheme();

		let stateColor = '';
		let stateIcon;

		switch (ticket.state.name) {
			case 'Aberto':
				stateColor = palette.info.main;
				stateIcon = <StarIcon fontSize="inherit" color="inherit" />;

				break;

			case 'Aguardando':
			case 'Em andamento':
				stateColor = palette.text.primary;
				stateIcon = <AccessTimeIcon fontSize="inherit" color="inherit" />;

				break;

			case 'Finalizado':
				stateColor = palette.success.main;
				stateIcon = <CheckCircleIcon fontSize="inherit" color="inherit" />;

				break;

			case 'Reaberto':
				stateColor = palette.info.main;
				stateIcon = <RotateRightIcon fontSize="inherit" color="inherit" />;

				break;

			default:
				stateColor = palette.text.primary;
				stateIcon = null;

				break;
		}

		if (isChip) {
			return (
				<Chip
					{...otherProps}
					label={
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 0.5,
							}}
						>
							{stateIcon}
							<Box component="span">{ticket.state.name}</Box>
						</Box>
					}
					sx={{
						backgroundColor: stateColor,
						color: palette.getContrastText(stateColor),
					}}
					ref={ref}
				/>
			);
		}

		return (
			<Box
				{...otherProps}
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 0.5,
					color: stateColor,
				}}
				ref={ref}
			>
				{stateIcon}
				<Typography variant="caption" noWrap>
					{ticket.state.name}
				</Typography>
			</Box>
		);
	}
);

TicketStatus.displayName = 'TicketStatus';
