import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { App } from './App';
import { AuthContainer } from './components/AuthContainer';
import { Dialog } from './components/Dialog';
import { Snackbar } from './components/Snackbar';
import { Spinner } from './components/Spinner';
import { ThemeContainer } from './components/ThemeContainer';
import { store } from './redux';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<AuthContainer>
				<ThemeContainer>
					<App />
					<Dialog />
					<Snackbar />
					<Spinner />
				</ThemeContainer>
			</AuthContainer>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
