import React from 'react';
import { AppContainer } from '../components/AppContainer';
import { TicketsList } from '../components/TicketsList';
import { useIsMobile } from '../hooks';

export const Home = (): JSX.Element => {
	const isMobile = useIsMobile();

	return (
		<AppContainer
			sx={{
				p: isMobile ? 2 : 4,
			}}
		>
			<TicketsList />
		</AppContainer>
	);
};
