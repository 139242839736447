import AddIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/ViewList';
import {
	AppBar,
	Box,
	Button,
	IconButton,
	Link,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Toolbar,
	Tooltip,
	useTheme as useMuiTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoDark from '../assets/logo_dark.svg';
import LogoLight from '../assets/logo_light.svg';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useAuth } from '../redux/slices/auth';
import { Auth } from '../services/Auth';
import { ChangeLanguageButton } from './ChangeLanguageButton';
import { ChangeModeButton } from './ChangeModeButton';
import { OtherAppsButton } from './OtherAppsButton';

export const Header = React.forwardRef<HTMLDivElement>((props, ref): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();
	const { palette } = useMuiTheme();

	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { snackbar, spinner } = useRedux();
	const { isLoggedIn } = useAuth();

	const [isDrawerOpen, setDrawerOpen] = useState(false);

	const handleLogout = async () => {
		spinner.show();

		try {
			await Auth.logout();

			spinner.hide();
			snackbar.show({ status: 'success', message: 'auth.success.logout' });
		} catch (err) {
			spinner.hide();
			snackbar.show({ status: 'error', message: 'auth.errors.logout' });
		}
	};

	return (
		<>
			<AppBar position="sticky" enableColorOnDark ref={ref}>
				<Toolbar
					sx={{
						gap: isMobile ? 0.5 : 1,
					}}
				>
					{isLoggedIn && (
						<>
							{location.pathname !== '/' && (
								<IconButton
									size={isMobile ? 'small' : 'medium'}
									color="inherit"
									onClick={() => navigate(-1)}
								>
									<ArrowBackIcon />
								</IconButton>
							)}

							<IconButton
								size={isMobile ? 'small' : 'medium'}
								color="inherit"
								onClick={() => setDrawerOpen(!isDrawerOpen)}
							>
								<MenuIcon />
							</IconButton>
						</>
					)}

					{!isMobile && (
						<Link
							component="button"
							variant="h6"
							underline="none"
							color="inherit"
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: 1,
							}}
							onClick={() => navigate('/')}
						>
							<Box
								component="img"
								src={palette.mode === 'light' ? LogoDark : LogoLight}
								alt={i18n('app.title.short')}
								height={64}
							/>
							{i18n('app.title')}
						</Link>
					)}

					<Box
						sx={{
							flex: 1,
						}}
					/>

					{isLoggedIn &&
						(isMobile ? (
							<Tooltip title={i18n('auth.logout')}>
								<IconButton
									size={isMobile ? 'small' : 'medium'}
									color="inherit"
									onClick={handleLogout}
								>
									<LogoutIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Button color="inherit" startIcon={<LogoutIcon />} onClick={handleLogout}>
								{i18n('auth.logout')}
							</Button>
						))}

					<ChangeModeButton />

					<ChangeLanguageButton />

					{isLoggedIn && <OtherAppsButton />}
				</Toolbar>
			</AppBar>

			{isLoggedIn && (
				<SwipeableDrawer
					open={isDrawerOpen}
					anchor="left"
					sx={{
						'.MuiListItemIcon-root': {
							minWidth: 'unset',
							mr: 2,
						},
					}}
					onOpen={() => setDrawerOpen(true)}
					onClose={() => setDrawerOpen(false)}
				>
					<List>
						<ListItemButton onClick={() => navigate('/tickets/new')}>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary={i18n('tickets.new')} />
						</ListItemButton>

						<ListItemButton onClick={() => navigate('/')}>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary={i18n('tickets.list')} />
						</ListItemButton>
					</List>
				</SwipeableDrawer>
			)}
		</>
	);
});

Header.displayName = 'Header';
