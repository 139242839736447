import { Menu, styled } from '@mui/material';
import React from 'react';

export const StyledMenu = styled<typeof Menu>((props) => <Menu {...props} />)(({ theme }) => {
	const { palette } = theme;

	if (palette.mode === 'dark') {
		return {};
	}

	return {
		'& .MuiMenuItem-root.Mui-selected': {
			backgroundColor: 'rgba(25, 118, 210, 0.24)',
			color: palette.text.primary,

			'&:hover': {
				backgroundColor: 'rgba(25, 118, 210, 0.28)',
				color: palette.text.primary,
			},
		},
	};
});
