import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortIcon from '@mui/icons-material/SortByAlpha';
import {
	Box,
	Button,
	Divider,
	Fab,
	IconButton,
	InputAdornment,
	List,
	MenuItem,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useAuth } from '../redux/slices/auth';
import { useTickets } from '../redux/slices/tickets';
import { Api } from '../services/Api';
import { StyledMenu } from './StyledMenu';
import { TicketsListItem } from './TicketsListItem';
import type { TicketData, TicketsApiDataItem } from '../redux/slices/tickets';

export interface TicketsListFilter {
	key: keyof TicketData;
	value: unknown;
	comparisonFn?: (ticket: TicketData) => boolean;
}

export interface TicketsListSort {
	key: keyof TicketData;
	asc: boolean;
}

export const TicketsList = (): JSX.Element => {
	const navigate = useNavigate();

	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { snackbar } = useRedux();
	const { user } = useAuth();
	const { apiData } = useTickets();

	const [isLoading, setLoading] = useState(true);
	const [originalTickets, setOriginalTickets] = useState<TicketData[]>([]);
	const [visibleTickets, setVisibleTickets] = useState<TicketData[]>([]);
	const [searchText, setSearchText] = useState('');
	const [filters, setFilters] = useState<TicketsListFilter[] | null>(null);
	const [sort, setSort] = useState<TicketsListSort>({ key: 'number', asc: false });
	const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
	const [isSortMenuOpen, setSortMenuOpen] = useState(false);

	const filterAnchorRef = useRef<HTMLButtonElement>(null);
	const sortAnchorRef = useRef<HTMLButtonElement>(null);

	const loadTickets = useCallback(async () => {
		if (!user?.email) {
			return;
		}

		setLoading(true);

		try {
			const newOriginalTickets = await Api.tickets.list({
				email: user.email,
			});
			setOriginalTickets(newOriginalTickets);
		} catch (err) {
			snackbar.show({ status: 'error', message: 'tickets.list.errors.load' });
		}

		setLoading(false);
	}, [user]);

	const handleFilterClick = (state: TicketsApiDataItem | null) => {
		setFilters((prevFilters) => {
			if (state) {
				const filters = prevFilters || [];
				const existingFilterIndex = filters.findIndex(
					(filter) => filter.key === 'state' && filter.value === state.name
				);

				if (existingFilterIndex > -1) {
					filters.splice(existingFilterIndex, 1);

					return [...filters];
				} else {
					return [
						...filters,
						{
							key: 'state',
							value: state.name,
							comparisonFn: (ticket) => ticket.state.name === state.name,
						},
					];
				}
			}

			return null;
		});
	};

	const handleSortClick = (key: keyof TicketData, asc: boolean) => {
		setSort({ key, asc });
		setSortMenuOpen(false);
	};

	useEffect(() => {
		void loadTickets();
	}, []);

	useEffect(() => {
		const newFilters: TicketsListFilter[] = [];

		for (const state of apiData.states) {
			if (state.name !== 'Finalizado') {
				newFilters.push({
					key: 'state',
					value: state.name,
					comparisonFn: (ticket) => ticket.state.name === state.name,
				});
			}
		}

		setFilters(newFilters);
	}, []);

	useEffect(() => {
		let newVisibleTickets = [...originalTickets];

		if (filters) {
			newVisibleTickets = newVisibleTickets.filter((ticket) => {
				for (const filter of filters) {
					if (filter.comparisonFn) {
						if (filter.comparisonFn(ticket)) {
							return true;
						}
					}

					if (ticket[filter.key] === filter.value) {
						return true;
					}
				}

				return false;
			});
		}

		if (searchText) {
			newVisibleTickets = newVisibleTickets.filter((ticket) =>
				ticket.subject.toLowerCase().includes(searchText.toLowerCase())
			);
		}

		const sortMultiplier = sort.asc ? 1 : -1;

		newVisibleTickets = newVisibleTickets.sort((ticketA, ticketB) => {
			const valueA = ticketA[sort.key];
			const valueB = ticketB[sort.key];

			if (typeof valueA === 'string' && typeof valueB === 'string') {
				return (
					valueA.localeCompare(valueB, undefined, {
						sensitivity: 'base',
						numeric: sort.key === 'number',
					}) * sortMultiplier
				);
			}

			if (valueA && valueB) {
				if (valueA < valueB) {
					return -1 * sortMultiplier;
				}

				if (valueB < valueA) {
					return 1 * sortMultiplier;
				}

				return 0;
			}

			if (valueA) {
				return -1 * sortMultiplier;
			}

			if (valueB) {
				return 1 * sortMultiplier;
			}

			return 0;
		});

		setVisibleTickets(newVisibleTickets);
	}, [originalTickets, searchText, filters, sort]);

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					gap: 2,
					p: isMobile ? 2 : 4,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: 1,
					}}
				>
					<Typography variant="h5">{i18n('tickets.list')}</Typography>

					<Tooltip title={i18n('tickets.list.refresh')}>
						<Box component="span">
							<IconButton color="primary" disabled={isLoading} onClick={loadTickets}>
								<RefreshIcon />
							</IconButton>
						</Box>
					</Tooltip>
				</Box>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 0.5,
						}}
					>
						<TextField
							id="search"
							size="small"
							label={i18n('tickets.list.search')}
							value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>

						<Tooltip title={i18n('tickets.list.filter')}>
							<IconButton
								ref={filterAnchorRef}
								onClick={() => setFilterMenuOpen(!isFilterMenuOpen)}
							>
								<FilterIcon />
							</IconButton>
						</Tooltip>

						<StyledMenu
							open={isFilterMenuOpen}
							anchorEl={filterAnchorRef.current}
							onClose={() => setFilterMenuOpen(false)}
						>
							<MenuItem selected={!filters} onClick={() => handleFilterClick(null)}>
								{i18n('tickets.list.filter.all')}
							</MenuItem>

							{apiData.states.map((state) => (
								<MenuItem
									key={state.id}
									selected={
										!!filters &&
										filters.some((filter) => filter.key === 'state' && filter.value === state.name)
									}
									onClick={() => handleFilterClick(state)}
								>
									{i18n('tickets.list.filter.status')} - {state.name}
								</MenuItem>
							))}
						</StyledMenu>

						<Tooltip title={i18n('tickets.list.sort')}>
							<IconButton ref={sortAnchorRef} onClick={() => setSortMenuOpen(!isSortMenuOpen)}>
								<SortIcon />
							</IconButton>
						</Tooltip>

						<StyledMenu
							open={isSortMenuOpen}
							anchorEl={sortAnchorRef.current}
							onClose={() => setSortMenuOpen(false)}
						>
							<MenuItem
								selected={sort.key === 'number' && sort.asc}
								onClick={() => handleSortClick('number', true)}
							>
								{i18n('tickets.list.sort.number')} - {i18n('tickets.list.sort.ascending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'number' && !sort.asc}
								onClick={() => handleSortClick('number', false)}
							>
								{i18n('tickets.list.sort.number')} - {i18n('tickets.list.sort.descending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'subject' && sort.asc}
								onClick={() => handleSortClick('subject', true)}
							>
								{i18n('tickets.list.sort.subject')} - {i18n('tickets.list.sort.ascending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'subject' && !sort.asc}
								onClick={() => handleSortClick('subject', false)}
							>
								{i18n('tickets.list.sort.subject')} - {i18n('tickets.list.sort.descending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'creationDate' && sort.asc}
								onClick={() => handleSortClick('creationDate', true)}
							>
								{i18n('tickets.list.sort.creationDate')} - {i18n('tickets.list.sort.ascending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'creationDate' && !sort.asc}
								onClick={() => handleSortClick('creationDate', false)}
							>
								{i18n('tickets.list.sort.creationDate')} - {i18n('tickets.list.sort.descending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'lastChangeDate' && sort.asc}
								onClick={() => handleSortClick('lastChangeDate', true)}
							>
								{i18n('tickets.list.sort.lastChangeDate')} - {i18n('tickets.list.sort.ascending')}
							</MenuItem>

							<MenuItem
								selected={sort.key === 'lastChangeDate' && !sort.asc}
								onClick={() => handleSortClick('lastChangeDate', false)}
							>
								{i18n('tickets.list.sort.lastChangeDate')} - {i18n('tickets.list.sort.descending')}
							</MenuItem>
						</StyledMenu>
					</Box>

					{!isMobile && (
						<Button
							variant="contained"
							startIcon={<AddCircleIcon />}
							onClick={() => navigate('/tickets/new')}
						>
							{i18n('tickets.new')}
						</Button>
					)}
				</Box>
			</Box>

			{isLoading ? (
				<List>
					{new Array(5).fill(null).map((ticket, i) => (
						<React.Fragment key={i}>
							<Divider />

							<TicketsListItem ticket={ticket} />
						</React.Fragment>
					))}
				</List>
			) : visibleTickets.length > 0 ? (
				<List>
					{visibleTickets.map((ticket) => (
						<React.Fragment key={ticket.id}>
							<Divider />

							<TicketsListItem ticket={ticket} />
						</React.Fragment>
					))}
				</List>
			) : (
				<Box
					sx={{
						p: 2,
					}}
				>
					<Typography textAlign="center">
						{originalTickets.length > 0 ? (
							<>
								{i18n('tickets.list.notFound')}
								<br />
								<br />
								{i18n('tickets.list.notFound.useButtonPrefix')}
								{' ('}
								<FilterIcon
									sx={{
										verticalAlign: 'middle',
									}}
								/>
								{') '}
								{i18n('tickets.list.notFound.useButtonSuffix')}
							</>
						) : (
							i18n('tickets.list.empty')
						)}
					</Typography>
				</Box>
			)}

			{isMobile && (
				<Tooltip title={i18n('tickets.new')}>
					<Fab
						size="medium"
						color="primary"
						sx={{
							position: 'fixed',
							bottom: ({ spacing }) => spacing(2),
							right: ({ spacing }) => spacing(2),
						}}
						onClick={() => navigate('/tickets/new')}
					>
						<AddIcon />
					</Fab>
				</Tooltip>
			)}
		</Paper>
	);
};
