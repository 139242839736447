import { enUS, ptBR } from '@mui/material/locale';
import { useCallback } from 'react';
import { useTheme } from '../redux/slices/theme';
import enUSMessages from './locales/en-US/messages.json';
import ptBRMessages from './locales/pt-BR/messages.json';

export type I18NKey = keyof typeof ptBRMessages | '';

export type Language = keyof typeof locales;

export const locales = {
	'en-US': {
		name: 'English',
		locale: enUS,
		messages: enUSMessages,
	},
	'pt-BR': {
		name: 'Português (Brasil)',
		locale: ptBR,
		messages: ptBRMessages,
	},
} as const;

export const useI18N = () => {
	const { language } = useTheme();

	const i18n = useCallback(
		(key: I18NKey) => {
			return (key !== '' && locales[language].messages[key]) || '';
		},
		[language]
	);

	return i18n;
};
