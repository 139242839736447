import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useAuth } from '../redux/slices/auth';
import {
	TICKET_CATEGORIES,
	TICKET_ENVIRONMENTS,
	TICKET_FINANCIAL_IMPACTS,
	TICKET_IMPACTED_USERS,
	TICKET_OPERATION_STATUSES,
	TICKET_PRIORITIES,
	TICKET_PRODUCTS,
	useTickets,
} from '../redux/slices/tickets';
import { Api } from '../services/Api';
import { FileDropArea } from './FileDropArea';
import { RichTextEditor } from './RichTextEditor';
import type { I18NKey } from '../i18n';
import type {
	TicketCategory,
	TicketEnvironment,
	TicketFinancialImpact,
	TicketImpactedUsers,
	TicketOperationStatus,
	TicketPriority,
	TicketProduct,
	TicketsApiDataItem,
} from '../redux/slices/tickets';
import type { GetValuesFn } from './RichTextEditor';

export interface TicketFormErrors {
	title: I18NKey;
	description: I18NKey;
	shortDescription: I18NKey;
	group: I18NKey;
	type: I18NKey;
	product: I18NKey;
	category: I18NKey;
	environment: I18NKey;
	operationStatus: I18NKey;
	impactedUsers: I18NKey;
	financialImpact: I18NKey;
	priority: I18NKey;
}

export const TicketForm = (): JSX.Element => {
	const navigate = useNavigate();

	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { snackbar } = useRedux();
	const { user, company } = useAuth();
	const { apiData } = useTickets();

	const [isCreating, setCreating] = useState(false);
	const [title, setTitle] = useState('');
	const [descriptionRaw, setDescriptionRaw] = useState('');
	const [shortDescription, setShortDescription] = useState('');
	const [group, setGroup] = useState<TicketsApiDataItem | null>(null);
	const [type, setType] = useState<TicketsApiDataItem | null>(null);
	const [state, setState] = useState<TicketsApiDataItem | null>(null);
	const [product, setProduct] = useState<TicketProduct | null>(null);
	const [category, setCategory] = useState<TicketCategory | null>(null);
	const [environment, setEnvironment] = useState<TicketEnvironment | null>(null);
	const [operationStatus, setOperationStatus] = useState<TicketOperationStatus | null>(null);
	const [impactedUsers, setImpactedUsers] = useState<TicketImpactedUsers | null>(null);
	const [financialImpact, setFinancialImpact] = useState<TicketFinancialImpact | null>(null);
	const [priority, setPriority] = useState<TicketPriority | null>(null);
	const [files, setFiles] = useState<File[]>([]);
	const [errors, setErrors] = useState<TicketFormErrors>({
		title: '',
		description: '',
		shortDescription: '',
		group: '',
		type: '',
		product: '',
		category: '',
		environment: '',
		operationStatus: '',
		impactedUsers: '',
		financialImpact: '',
		priority: '',
	});

	const getDescriptionValuesRef = useRef<GetValuesFn>(null);

	const handleClear = () => {
		setTitle('');
		setDescriptionRaw('');
		setShortDescription('');
		setGroup(null);
		setType(null);
		setProduct(null);
		setCategory(null);
		setEnvironment(null);
		setOperationStatus(null);
		setImpactedUsers(null);
		setFinancialImpact(null);
		setPriority(null);
		setFiles([]);
		setErrors({
			title: '',
			description: '',
			shortDescription: '',
			group: '',
			type: '',
			product: '',
			category: '',
			environment: '',
			operationStatus: '',
			impactedUsers: '',
			financialImpact: '',
			priority: '',
		});
	};

	const handleCreateTicket = async () => {
		const newErrors: TicketFormErrors = {
			title: '',
			description: '',
			shortDescription: '',
			group: '',
			type: '',
			product: '',
			category: '',
			environment: '',
			operationStatus: '',
			impactedUsers: '',
			financialImpact: '',
			priority: '',
		};
		let hasErrors = false;

		if (!state || !user?.email || !getDescriptionValuesRef.current) {
			snackbar.show({ status: 'error', message: 'tickets.form.errors.create' });

			return;
		}

		const [descriptionRaw, description] = await getDescriptionValuesRef.current();
		setDescriptionRaw(descriptionRaw);

		if (!title) {
			newErrors.title = 'tickets.form.errors.title.empty';
			hasErrors = true;
		}

		if (!description || description === '<p></p>') {
			newErrors.description = 'tickets.form.errors.description.empty';
			hasErrors = true;
		}

		if (!shortDescription) {
			newErrors.shortDescription = 'tickets.form.errors.shortDescription.empty';
			hasErrors = true;
		}

		if (!group) {
			newErrors.group = 'tickets.form.errors.group.empty';
			hasErrors = true;
		}

		if (!type) {
			newErrors.type = 'tickets.form.errors.type.empty';
			hasErrors = true;
		}

		if (!product) {
			newErrors.product = 'tickets.form.errors.product.empty';
			hasErrors = true;
		}

		if (!category) {
			newErrors.category = 'tickets.form.errors.category.empty';
			hasErrors = true;
		}

		if (!environment) {
			newErrors.environment = 'tickets.form.errors.environment.empty';
			hasErrors = true;
		}

		if (!operationStatus) {
			newErrors.operationStatus = 'tickets.form.errors.operationStatus.empty';
			hasErrors = true;
		}

		if (!impactedUsers) {
			newErrors.impactedUsers = 'tickets.form.errors.impactedUsers.empty';
			hasErrors = true;
		}

		if (!financialImpact) {
			newErrors.financialImpact = 'tickets.form.errors.financialImpact.empty';
			hasErrors = true;
		}

		if (!priority) {
			newErrors.priority = 'tickets.form.errors.priority.empty';
			hasErrors = true;
		}

		if (
			!group ||
			!type ||
			!product ||
			!category ||
			!environment ||
			!operationStatus ||
			!impactedUsers ||
			!financialImpact ||
			!priority ||
			hasErrors
		) {
			setErrors(newErrors);

			return;
		}

		setCreating(true);

		try {
			const response = await Api.tickets.create({
				title,
				description,
				shortDescription,
				group: group.id,
				type: type.id,
				state: state.id,
				email: user.email,
				name: user.name,
				product,
				category,
				cnpj: company?.cnpj ?? '',
				phone: user.phone,
				environment,
				operationStatus,
				impactedUsers,
				financialImpact,
				priority,
			});
			for (const file of files) {
				await Api.tickets.addAttachment(response.ticketId, file);
			}

			snackbar.show({ status: 'success', message: 'tickets.form.success.create' });
			navigate(`/tickets/${response.ticketId}`);
		} catch (err) {
			snackbar.show({ status: 'error', message: 'tickets.form.errors.create' });
			setCreating(false);
		}
	};

	useEffect(() => {
		const newState = apiData.states.find((currentState) => currentState.name === 'Aberto');

		if (newState) {
			setState(newState);
		}
	}, [apiData]);

	return (
		<Paper
			elevation={6}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 2,
				p: isMobile ? 2 : 4,

				'> *': {
					width: 1,
				},
			}}
		>
			<Typography variant="h5">{i18n('tickets.new')}</Typography>

			<Typography>{i18n('tickets.new.subtitle')}</Typography>

			<TextField
				id="title"
				label={i18n('tickets.form.title')}
				value={title}
				required
				disabled={isCreating}
				error={!!errors.title}
				helperText={errors.title ? i18n(errors.title) : ''}
				onChange={(e) => {
					setTitle(e.target.value);

					if (errors.title) {
						setErrors((prevErrors) => ({
							...prevErrors,
							title: '',
						}));
					}
				}}
			/>

			<RichTextEditor
				id="description"
				label="tickets.form.description"
				defaultValue={descriptionRaw}
				isDisabled={isCreating}
				errorMessage={errors.description}
				getValuesRef={getDescriptionValuesRef}
				onFocus={() => {
					if (errors.description) {
						setErrors((prevErrors) => ({
							...prevErrors,
							description: '',
						}));
					}
				}}
			/>

			<TextField
				id="shortDescription"
				label={i18n('tickets.form.shortDescription')}
				value={shortDescription}
				required
				disabled={isCreating}
				inputProps={{
					maxLength: 240,
				}}
				error={!!errors.shortDescription}
				helperText={errors.shortDescription ? i18n(errors.shortDescription) : ''}
				onChange={(e) => {
					setShortDescription(e.target.value);

					if (errors.shortDescription) {
						setErrors((prevErrors) => ({
							...prevErrors,
							shortDescription: '',
						}));
					}
				}}
			/>

			<Grid
				container
				spacing={2}
				sx={{
					'> .MuiGrid-item': {
						width: 1,

						'> *': {
							width: 1,
						},
					},
				}}
			>
				<Grid item xs={12} md={3}>
					<Autocomplete
						id="group"
						options={apiData.groups}
						getOptionLabel={(currentGroup) => currentGroup.name}
						value={group}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.group')}
								required
								error={!!errors.group}
								helperText={errors.group ? i18n(errors.group) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setGroup(newValue);

							if (errors.group) {
								setErrors((prevErrors) => ({
									...prevErrors,
									group: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="type"
						options={apiData.types}
						getOptionLabel={(currentType) => currentType.name}
						value={type}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.type')}
								required
								error={!!errors.type}
								helperText={errors.type ? i18n(errors.type) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setType(newValue);

							if (errors.type) {
								setErrors((prevErrors) => ({
									...prevErrors,
									type: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="product"
						options={TICKET_PRODUCTS}
						value={product}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.product')}
								required
								error={!!errors.product}
								helperText={errors.product ? i18n(errors.product) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setProduct(newValue);

							if (errors.product) {
								setErrors((prevErrors) => ({
									...prevErrors,
									product: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="category"
						options={TICKET_CATEGORIES}
						value={category}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.category')}
								required
								error={!!errors.category}
								helperText={errors.category ? i18n(errors.category) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setCategory(newValue);

							if (errors.category) {
								setErrors((prevErrors) => ({
									...prevErrors,
									category: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="environment"
						options={TICKET_ENVIRONMENTS}
						value={environment}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.environment')}
								required
								error={!!errors.environment}
								helperText={errors.environment ? i18n(errors.environment) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setEnvironment(newValue);

							if (errors.environment) {
								setErrors((prevErrors) => ({
									...prevErrors,
									environment: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="operationStatus"
						options={TICKET_OPERATION_STATUSES}
						value={operationStatus}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.operationStatus')}
								required
								error={!!errors.operationStatus}
								helperText={errors.operationStatus ? i18n(errors.operationStatus) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setOperationStatus(newValue);

							if (errors.operationStatus) {
								setErrors((prevErrors) => ({
									...prevErrors,
									operationStatus: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="impactedUsers"
						options={TICKET_IMPACTED_USERS}
						value={impactedUsers}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.impactedUsers')}
								required
								error={!!errors.impactedUsers}
								helperText={errors.impactedUsers ? i18n(errors.impactedUsers) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setImpactedUsers(newValue);

							if (errors.impactedUsers) {
								setErrors((prevErrors) => ({
									...prevErrors,
									impactedUsers: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="financialImpact"
						options={TICKET_FINANCIAL_IMPACTS}
						value={financialImpact}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.financialImpact')}
								required
								error={!!errors.financialImpact}
								helperText={errors.financialImpact ? i18n(errors.financialImpact) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setFinancialImpact(newValue);

							if (errors.financialImpact) {
								setErrors((prevErrors) => ({
									...prevErrors,
									financialImpact: '',
								}));
							}
						}}
					/>
				</Grid>

				<Grid item xs={12} md={3}>
					<Autocomplete
						id="priority"
						options={TICKET_PRIORITIES}
						value={priority}
						disabled={isCreating}
						disablePortal
						openOnFocus
						renderInput={(params) => (
							<TextField
								{...params}
								label={i18n('tickets.form.priority')}
								required
								error={!!errors.priority}
								helperText={errors.priority ? i18n(errors.priority) : ''}
							/>
						)}
						onChange={(e, newValue) => {
							setPriority(newValue);

							if (errors.priority) {
								setErrors((prevErrors) => ({
									...prevErrors,
									priority: '',
								}));
							}
						}}
					/>
				</Grid>
			</Grid>

			<Typography variant="h6">{i18n('tickets.form.attachments')}</Typography>

			<FileDropArea isDisabled={isCreating} files={files} setFiles={setFiles} />

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 2,

					...(isMobile
						? {
								flexDirection: 'column',
						  }
						: {}),

					'.MuiButton-root': {
						width: 200,
						maxWidth: 200,
					},
				}}
			>
				<Button variant="outlined" disabled={isCreating} onClick={handleClear}>
					{i18n('tickets.form.clear')}
				</Button>

				<LoadingButton variant="contained" loading={isCreating} onClick={handleCreateTicket}>
					{i18n('tickets.form.create')}
				</LoadingButton>
			</Box>
		</Paper>
	);
};
