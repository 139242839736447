import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Storage } from '../services/Storage';
import { authSlice } from './slices/auth';
import { dialogSlice } from './slices/dialog';
import { snackbarSlice } from './slices/snackbar';
import { spinnerSlice } from './slices/spinner';
import { themeSlice } from './slices/theme';
import { ticketsSlice } from './slices/tickets';
import type { Middleware, PayloadAction } from '@reduxjs/toolkit';
import type { AuthPayload } from './slices/auth';
import type { DialogPayload, DialogUpdatePayload } from './slices/dialog';
import type { SnackbarPayload } from './slices/snackbar';
import type { ThemePayload, ThemeState } from './slices/theme';
import type { TicketsPayload, TicketsState } from './slices/tickets';

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const rootReducer = combineReducers({
	auth: authSlice.reducer,
	dialog: dialogSlice.reducer,
	snackbar: snackbarSlice.reducer,
	spinner: spinnerSlice.reducer,
	theme: themeSlice.reducer,
	tickets: ticketsSlice.reducer,
});

const persistenceMiddleware: Middleware<unknown, RootState> = (store) => {
	return (next) => (action: PayloadAction) => {
		const result = next(action);

		if (action.type.includes('theme')) {
			Storage.local.set('redux-theme', store.getState().theme);
		} else if (action.type.includes('tickets')) {
			Storage.local.set('redux-tickets-api-data', store.getState().tickets.apiData);
		}

		return result;
	};
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistenceMiddleware),
	preloadedState: {
		theme: {
			mode: 'system',
			language: 'pt-BR',
			...(Storage.local.get<ThemeState>('redux-theme') ?? {}),
		},
		tickets: {
			apiData: {
				groups: [],
				types: [],
				states: [],
				users: [],
				...(Storage.local.get<TicketsState>('redux-tickets-api-data', 60 * 60 * 24) ?? {}),
			},
			tickets: {
				value: [],
				updatedAt: 0,
			},
			ticketsComments: {},
		},
	},
});

export const useRedux = () => {
	const dispatch = useDispatch<AppDispatch>();

	return {
		auth: {
			login: (payload: AuthPayload) => dispatch(authSlice.actions.login(payload)),

			logout: () => dispatch(authSlice.actions.logout()),
		},

		dialog: {
			show: (payload: DialogPayload) => dispatch(dialogSlice.actions.show(payload)),

			hide: () => dispatch(dialogSlice.actions.hide()),

			update: (payload: DialogUpdatePayload) => dispatch(dialogSlice.actions.update(payload)),
		},

		snackbar: {
			show: (payload: SnackbarPayload) => dispatch(snackbarSlice.actions.show(payload)),

			hide: () => dispatch(snackbarSlice.actions.hide()),
		},

		spinner: {
			show: () => dispatch(spinnerSlice.actions.show()),

			hide: () => dispatch(spinnerSlice.actions.hide()),
		},

		theme: {
			update: (payload: ThemePayload) => dispatch(themeSlice.actions.update(payload)),
		},

		tickets: {
			update: (payload: TicketsPayload) => dispatch(ticketsSlice.actions.update(payload)),
		},
	};
};
