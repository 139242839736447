import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, CircularProgress, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useAuth } from '../redux/slices/auth';
import { useDialog } from '../redux/slices/dialog';
import { useTheme } from '../redux/slices/theme';
import { Api } from '../services/Api';
import { FileDropArea } from './FileDropArea';
import { RichTextEditor } from './RichTextEditor';
import { TicketComment } from './TicketComment';
import { TicketStatus } from './TicketStatus';
import type { I18NKey } from '../i18n';
import type { DialogAction } from '../redux/slices/dialog';
import type {
	TicketAttachmentData,
	TicketCommentData,
	TicketCustomFieldData,
	TicketData,
} from '../redux/slices/tickets';
import type { GetValuesFn } from './RichTextEditor';

export interface TicketViewProps {
	id?: string;
}

export interface TicketViewErrors {
	answerText: I18NKey;
}

export const TicketView = ({ id }: TicketViewProps): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { dialog, snackbar } = useRedux();
	const { user } = useAuth();
	const { action: dialogAction, data: dialogData } = useDialog();
	const { language } = useTheme();

	const [isLoading, setLoading] = useState(true);
	const [isLoadingAttachments, setLoadingAttachments] = useState(true);
	const [isLoadingComments, setLoadingComments] = useState(true);
	const [isCanceling, setCanceling] = useState(false);
	const [isClosing, setClosing] = useState(false);
	const [isReopening, setReopening] = useState(false);
	const [isDownloadingAttachment, setDownloadingAttachment] = useState<boolean[]>([]);
	const [isSending, setSending] = useState(false);
	const [ticket, setTicket] = useState<TicketData | null>(null);
	const [customFields, setCustomFields] = useState<TicketCustomFieldData[]>([]);
	const [attachments, setAttachments] = useState<TicketAttachmentData[]>([]);
	const [comments, setComments] = useState<TicketCommentData[]>([]);
	const [product, setProduct] = useState('');
	const [answerTextRaw, setAnswerTextRaw] = useState('');
	const [files, setFiles] = useState<File[]>([]);
	const [errors, setErrors] = useState<TicketViewErrors>({
		answerText: '',
	});

	const getAnswerValuesRef = useRef<GetValuesFn>(null);

	const dateTimeFormatter = useMemo(
		() =>
			new Intl.DateTimeFormat(language, {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				hour12: false,
				minute: '2-digit',
				second: '2-digit',
			}),
		[language]
	);

	const isOpen = useMemo(() => ticket?.state.name !== 'Finalizado', [ticket]);

	const loadTicket = useCallback(
		async (isInitialLoad?: boolean) => {
			if (!id) {
				return;
			}

			if (isInitialLoad) {
				setLoading(true);
			}

			try {
				const newTicket = await Api.tickets.get(id);
				const newCustomFields = await Api.tickets.getCustomFields(id);
				setTicket(newTicket);
				setCustomFields(newCustomFields);
			} catch (err) {
				snackbar.show({ status: 'error', message: 'tickets.view.errors.load' });
			}

			if (isInitialLoad) {
				setLoading(false);
			}
		},
		[id]
	);

	const loadAttachments = useCallback(
		async (isInitialLoad?: boolean) => {
			if (!id) {
				return;
			}

			if (isInitialLoad) {
				setLoadingAttachments(true);
			}

			try {
				const newAttachments = await Api.tickets.getAttachments(id);
				setAttachments(newAttachments);
				setDownloadingAttachment(new Array(newAttachments.length).fill(false));
			} catch (err) {
				snackbar.show({ status: 'error', message: 'tickets.view.errors.loadAttachments' });
			}

			if (isInitialLoad) {
				setLoadingAttachments(false);
			}
		},
		[id]
	);

	const loadComments = useCallback(
		async (isInitialLoad?: boolean) => {
			if (!id) {
				return;
			}

			if (isInitialLoad) {
				setLoadingComments(true);
			}

			try {
				let newComments = await Api.tickets.getComments(id);
				newComments = newComments.sort((commentA, commentB) => {
					if (commentA.date < commentB.date) {
						return -1;
					}

					if (commentB.date < commentA.date) {
						return 1;
					}

					return 0;
				});
				setComments(newComments);
			} catch (err) {
				snackbar.show({ status: 'error', message: 'tickets.view.errors.loadComments' });
			}

			if (isInitialLoad) {
				setLoadingComments(false);
			}
		},
		[id]
	);

	const handleClose = useCallback(
		async (action: DialogAction, data: Record<string, unknown>) => {
			if (!id) {
				return;
			}

			if (action === 'cancel-ticket') {
				setCanceling(true);
			} else {
				setClosing(true);
			}

			try {
				await Api.tickets.addComment(
					id,
					[
						`<strong>Usuário ${
							action === 'cancel-ticket' ? 'Cancelou' : 'Encerrou'
						} a Solicitação:</strong>`,
						`Motivo do ${action === 'cancel-ticket' ? 'cancelamento' : 'encerramento'}: ${
							data.reason
						}`,
						`Descrição enviada por ele: ${data.reasonDetails}`,
					].join('<br/>')
				);
				await Api.tickets.close(id);
				await loadTicket();
				await loadComments();

				snackbar.show({ status: 'success', message: 'tickets.view.success.close' });
			} catch (err) {
				snackbar.show({ status: 'error', message: 'tickets.view.errors.close' });
			}

			if (action === 'cancel-ticket') {
				setCanceling(false);
			} else {
				setClosing(false);
			}
		},
		[id, loadTicket]
	);

	const handleReopen = useCallback(async () => {
		if (!id) {
			return;
		}

		setReopening(true);

		try {
			await Api.tickets.reopen(id);
			await loadTicket();

			snackbar.show({ status: 'success', message: 'tickets.view.success.reopen' });
		} catch (err) {
			snackbar.show({ status: 'error', message: 'tickets.view.errors.reopen' });
		}

		setReopening(false);
	}, [id, loadTicket]);

	const handleDownloadAttachment = async (attachment: TicketAttachmentData, i: number) => {
		setDownloadingAttachment((prevDownloadingAttachment) => {
			prevDownloadingAttachment[i] = true;

			return [...prevDownloadingAttachment];
		});

		try {
			const file = await Api.tickets.downloadAttachment(attachment.url);
			const url = URL.createObjectURL(file);

			const anchor = document.createElement('a');
			anchor.style.display = 'none';
			document.body.appendChild(anchor);
			anchor.download = attachment.name;
			anchor.href = url;
			anchor.dispatchEvent(new MouseEvent('click'));
			anchor.remove();

			URL.revokeObjectURL(url);
		} catch (err) {
			snackbar.show({ status: 'error', message: 'tickets.view.errors.downloadAttachment' });
		}

		setDownloadingAttachment((prevDownloadingAttachment) => {
			prevDownloadingAttachment[i] = false;

			return [...prevDownloadingAttachment];
		});
	};

	const handleSend = useCallback(async () => {
		const newErrors: TicketViewErrors = {
			answerText: '',
		};
		let hasErrors = false;

		if (!id || !getAnswerValuesRef.current) {
			snackbar.show({ status: 'error', message: 'tickets.view.errors.send' });

			return;
		}

		const [answerTextRaw, answerText] = await getAnswerValuesRef.current();
		setAnswerTextRaw(answerTextRaw);

		if (!answerText || answerText === '<p></p>') {
			newErrors.answerText = 'tickets.view.errors.answer.empty';
			hasErrors = true;
		}

		if (hasErrors) {
			setErrors(newErrors);

			return;
		}

		setSending(true);

		try {
			if (answerText) {
				await Api.tickets.addComment(id, answerText);
				await loadComments();
			}

			if (files.length > 0) {
				for (const file of files) {
					await Api.tickets.addAttachment(id, file);
				}

				await loadAttachments();
			}

			setAnswerTextRaw('');
			setFiles([]);
			setErrors((prevErrors) => ({
				...prevErrors,
				answerText: '',
			}));

			snackbar.show({ status: 'success', message: 'tickets.view.success.send' });
		} catch (err) {
			snackbar.show({ status: 'error', message: 'tickets.view.errors.send' });
		}

		setSending(false);
	}, [id, files, loadAttachments, loadComments]);

	useEffect(() => {
		const load = async () => {
			await loadTicket(true);
			await loadAttachments(true);
			await loadComments(true);
		};

		void load();
	}, [loadTicket, loadAttachments, loadComments]);

	useEffect(() => {
		const productField = customFields.find(
			(customField) => customField.question === 'Produto ou Serviços'
		);
		setProduct(productField?.answer ?? '');
	}, [customFields]);

	useEffect(() => {
		if (
			(dialogAction === 'cancel-ticket' || dialogAction === 'close-ticket') &&
			dialogData.reason &&
			dialogData.reasonDetails
		) {
			void handleClose(dialogAction, dialogData);
			dialog.update({ data: {} });
		}
	}, [dialogAction, dialogData]);

	return isLoading || ticket ? (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				gap: 2,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						gap: 1,
						minWidth: 0,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							gap: isMobile ? 0.5 : 1,
						}}
					>
						<Tooltip title={i18n('tickets.view.status')}>
							{ticket ? (
								<TicketStatus ticket={ticket} isChip />
							) : (
								<Chip label={<Skeleton variant="text" width={50} />} />
							)}
						</Tooltip>

						<Tooltip title={i18n('tickets.view.number')}>
							<Chip label={ticket ? ticket.number : <Skeleton variant="text" width={50} />} />
						</Tooltip>

						<Tooltip title={i18n('tickets.view.group')}>
							<Chip label={ticket ? ticket.group.name : <Skeleton variant="text" width={50} />} />
						</Tooltip>
					</Box>

					<Typography variant="h5" noWrap>
						{ticket ? ticket.subject : <Skeleton variant="text" width={isMobile ? 200 : 300} />}
					</Typography>

					{ticket ? (
						<>
							{product && (
								<Typography variant="caption" noWrap>
									{i18n('tickets.view.product')}:{' '}
									<Box
										component="span"
										sx={{
											fontWeight: 'bold',
										}}
									>
										{product}
									</Box>
								</Typography>
							)}

							<Typography variant="caption" noWrap>
								{i18n('tickets.view.createdBy')}:{' '}
								<Box
									component="span"
									sx={{
										fontWeight: 'bold',
									}}
								>
									{user?.name ?? '-'}
								</Box>
							</Typography>

							<Typography variant="caption" noWrap>
								{i18n('tickets.view.answeredBy')}:{' '}
								<Box
									component="span"
									sx={{
										fontWeight: 'bold',
									}}
								>
									{ticket.responsible?.name ?? '-'}
								</Box>
							</Typography>

							<Typography variant="caption" noWrap>
								{i18n('tickets.view.lastUpdate')}:{' '}
								<Box
									component="span"
									sx={{
										fontWeight: 'bold',
									}}
								>
									{dateTimeFormatter.format(new Date(ticket.lastChangeDate * 1e3))}
								</Box>
							</Typography>

							<Typography variant="caption" noWrap>
								{i18n('tickets.view.deadline')}:{' '}
								<Box
									component="span"
									sx={{
										fontWeight: 'bold',
									}}
								>
									{ticket.deadline
										? dateTimeFormatter.format(new Date(ticket.deadline * 1e3))
										: '-'}
								</Box>
							</Typography>
						</>
					) : (
						<Typography variant="caption">
							<Skeleton variant="text" width={150} />
						</Typography>
					)}
				</Box>

				{ticket &&
					(isOpen ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: 1,
							}}
						>
							<Tooltip title={isMobile ? i18n('tickets.view.cancel') : ''}>
								<LoadingButton
									variant="contained"
									color="error"
									startIcon={<CloseIcon />}
									disabled={isClosing || isSending}
									loading={isCanceling}
									loadingPosition="start"
									sx={
										isMobile
											? {
													width: 40,
													height: 40,
													minWidth: 40,
													p: 0,
													borderRadius: '50%',

													'.MuiButton-startIcon': {
														m: 0,
													},
											  }
											: {}
									}
									onClick={() => dialog.show({ action: 'cancel-ticket' })}
								>
									{isMobile ? '' : i18n('tickets.view.cancel')}
								</LoadingButton>
							</Tooltip>

							<Tooltip title={isMobile ? i18n('tickets.view.close') : ''}>
								<LoadingButton
									variant="contained"
									color="success"
									startIcon={<CheckIcon />}
									disabled={isCanceling || isSending}
									loading={isClosing}
									loadingPosition="start"
									sx={
										isMobile
											? {
													width: 40,
													height: 40,
													minWidth: 40,
													p: 0,
													borderRadius: '50%',

													'.MuiButton-startIcon': {
														m: 0,
													},
											  }
											: {}
									}
									onClick={() => dialog.show({ action: 'close-ticket' })}
								>
									{isMobile ? '' : i18n('tickets.view.close')}
								</LoadingButton>
							</Tooltip>
						</Box>
					) : (
						<Tooltip title={isMobile ? i18n('tickets.view.reopen') : ''}>
							<LoadingButton
								variant="contained"
								color="success"
								startIcon={<RotateRightIcon />}
								disabled={isSending}
								loading={isReopening}
								loadingPosition="start"
								sx={
									isMobile
										? {
												width: 40,
												height: 40,
												minWidth: 40,
												p: 0,
												borderRadius: '50%',

												'.MuiButton-startIcon': {
													m: 0,
												},
										  }
										: {}
								}
								onClick={handleReopen}
							>
								{isMobile ? '' : i18n('tickets.view.reopen')}
							</LoadingButton>
						</Tooltip>
					))}
			</Box>

			{ticket ? (
				<TicketComment
					comment={{
						id: ticket.id,
						date: ticket.creationDate,
						user: ticket.creationUser,
						content: ticket.description,
						public: true,
					}}
				/>
			) : (
				<TicketComment comment={null} />
			)}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'flex-start',
					gap: 2,
					ml: isMobile ? 4 : 8,
				}}
			>
				<Typography variant="h6">{i18n('tickets.view.attachments')}</Typography>

				{isLoadingAttachments ? (
					new Array(2).fill(null).map((attachment, i) => (
						<Chip
							key={i}
							label={
								<Typography variant="inherit">
									<Skeleton variant="text" width={100} />
								</Typography>
							}
							icon={<AttachFileIcon />}
							deleteIcon={<CloudDownloadIcon />}
							onDelete={() => {
								// Do nothing
							}}
						/>
					))
				) : attachments.length > 0 ? (
					attachments.map((attachment, i) => (
						<Chip
							key={attachment.name}
							label={
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 1,
									}}
								>
									<Typography variant="inherit" noWrap>
										{attachment.name}
									</Typography>

									<Typography variant="inherit" fontWeight="bold">
										({attachment.humanReadableLength})
									</Typography>
								</Box>
							}
							icon={<AttachFileIcon />}
							deleteIcon={
								isDownloadingAttachment[i] ? (
									<CircularProgress color="inherit" size="1rem" />
								) : (
									<CloudDownloadIcon />
								)
							}
							disabled={isDownloadingAttachment[i]}
							sx={{
								width: 'unset',
								maxWidth: isMobile ? 250 : 300,
							}}
							onDelete={() => handleDownloadAttachment(attachment, i)}
						/>
					))
				) : (
					<Typography>{i18n('tickets.view.noAttachmentsFound')}</Typography>
				)}

				<Typography variant="h6">{i18n('tickets.view.answers')}</Typography>

				{isLoadingComments ? (
					new Array(2).fill(null).map((comment, i) => <TicketComment key={i} comment={comment} />)
				) : comments.length > 0 ? (
					comments.map((comment) => <TicketComment key={comment.id} comment={comment} />)
				) : (
					<Typography>{i18n('tickets.view.noAnswersFound')}</Typography>
				)}

				{isOpen && (
					<Paper
						elevation={6}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'flex-end',
							gap: 2,
							width: 1,
							p: isMobile ? 1 : 2,
						}}
					>
						<RichTextEditor
							id="answer"
							label="tickets.view.answer"
							defaultValue={answerTextRaw}
							isDisabled={isLoading || isCanceling || isClosing || isSending}
							errorMessage={errors.answerText}
							getValuesRef={getAnswerValuesRef}
							onFocus={() => {
								if (errors.answerText) {
									setErrors((prevErrors) => ({
										...prevErrors,
										answerText: '',
									}));
								}
							}}
						/>

						<FileDropArea
							isDisabled={isLoading || isCanceling || isClosing || isSending}
							files={files}
							setFiles={setFiles}
						/>

						<LoadingButton
							variant="contained"
							startIcon={<SendIcon />}
							disabled={isLoading || isCanceling || isClosing}
							loading={isSending}
							loadingPosition="start"
							onClick={handleSend}
						>
							{i18n('tickets.view.send')}
						</LoadingButton>
					</Paper>
				)}
			</Box>
		</Box>
	) : (
		<Typography textAlign="center">{i18n('tickets.view.notFound')}</Typography>
	);
};
