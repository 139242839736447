import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { I18NKey } from '../../i18n';
import type { AlertColor } from '@mui/material';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';

export interface SnackbarState {
	isOpen: boolean;
	status: AlertColor;
	message: I18NKey;
}

export interface SnackbarPayload {
	status: AlertColor;
	message: I18NKey;
}

const initialState: SnackbarState = {
	isOpen: false,
	status: 'info',
	message: '',
};

export const snackbarSlice = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {
		show: (state, { payload }: PayloadAction<SnackbarPayload>) => {
			state.isOpen = true;
			state.status = payload.status;
			state.message = payload.message;
		},

		hide: (state) => {
			state.isOpen = false;
		},
	},
});

export const useSnackbar = () => useSelector<RootState, SnackbarState>((state) => state.snackbar);
