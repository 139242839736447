import { Box, Divider, ListItemButton, Skeleton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useTheme } from '../redux/slices/theme';
import { TicketStatus } from './TicketStatus';
import type { TicketData } from '../redux/slices/tickets';

export interface TicketsListItemProps {
	ticket: TicketData | null;
}

export const TicketsListItem = ({ ticket }: TicketsListItemProps) => {
	const navigate = useNavigate();

	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { language } = useTheme();

	const dateTimeFormatter = useMemo(
		() =>
			new Intl.DateTimeFormat(language, {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				hour12: false,
				minute: '2-digit',
				second: '2-digit',
			}),
		[language]
	);

	const dateParts = ticket
		? new Intl.DateTimeFormat(language, {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour: '2-digit',
				hour12: false,
				minute: '2-digit',
		  }).formatToParts(new Date(ticket.creationDate * 1e3))
		: [];
	let year;
	let day;
	let month;
	let hour;
	let minute;

	for (const datePart of dateParts) {
		switch (datePart.type) {
			case 'year':
				year = datePart.value;

				break;

			case 'day':
				day = datePart.value;

				break;

			case 'month':
				month = datePart.value.replace(/[^A-Za-z]/g, '').toUpperCase();

				break;

			case 'hour':
				hour = datePart.value;

				break;

			case 'minute':
				minute = datePart.value;

				break;

			default:
				break;
		}
	}

	const handleClick = () => {
		if (ticket) {
			navigate(`/tickets/${ticket.id}`);
		}
	};

	let topInfo;

	if (ticket) {
		topInfo = (
			<>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: 0.5,
					}}
				>
					<TicketStatus ticket={ticket} />

					<Typography variant="caption" noWrap>
						| # {ticket.number}
					</Typography>
				</Box>

				<Typography variant="caption" fontWeight="bold" noWrap>
					{isMobile ? '' : '| '}
					{ticket.group.name}
				</Typography>
			</>
		);
	} else {
		topInfo = (
			<Typography variant="caption">
				<Skeleton variant="text" width={isMobile ? 50 : 100} />
			</Typography>
		);
	}

	if (!isMobile) {
		topInfo = (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 0.5,
				}}
			>
				{topInfo}
			</Box>
		);
	}

	let bottomInfo;

	if (ticket) {
		bottomInfo = (
			<>
				<Typography variant="caption" noWrap>
					{i18n('tickets.view.answeredBy')}:{' '}
					<Box
						component="span"
						sx={{
							fontWeight: 'bold',
						}}
					>
						{ticket?.responsible?.name ?? '-'}
					</Box>
				</Typography>

				<Typography variant="caption" noWrap>
					{isMobile ? '' : '| '}
					{i18n('tickets.view.lastUpdate')}:{' '}
					<Box
						component="span"
						sx={{
							fontWeight: 'bold',
						}}
					>
						{dateTimeFormatter.format(new Date(ticket.lastChangeDate * 1e3))}
					</Box>
				</Typography>

				<Typography variant="caption" noWrap>
					{isMobile ? '' : '| '}
					{i18n('tickets.view.deadline')}:{' '}
					<Box
						component="span"
						sx={{
							fontWeight: 'bold',
						}}
					>
						{ticket.deadline ? dateTimeFormatter.format(new Date(ticket.deadline * 1e3)) : '-'}
					</Box>
				</Typography>
			</>
		);
	} else {
		bottomInfo = (
			<Typography variant="caption">
				<Skeleton variant="text" width={isMobile ? 100 : 200} />
			</Typography>
		);
	}

	if (!isMobile) {
		bottomInfo = (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 0.5,
				}}
			>
				{bottomInfo}
			</Box>
		);
	}

	return (
		<ListItemButton onClick={handleClick}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: 0.5,
					width: 1,
				}}
			>
				<Box
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						gap: 0.5,
						minWidth: 0,
					}}
				>
					{topInfo}

					<Typography fontWeight="bold" noWrap>
						{ticket ? ticket.subject : <Skeleton variant="text" width={isMobile ? 150 : 300} />}
					</Typography>

					{bottomInfo}
				</Box>

				<Box
					sx={{
						display: 'flex',
						gap: 1,
					}}
				>
					<Divider orientation="vertical" flexItem />

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: isMobile ? 50 : 75,
						}}
					>
						<Typography variant="caption" textAlign="center">
							{ticket ? year : <Skeleton variant="text" width={30} />}
						</Typography>

						<Typography variant="caption" textAlign="center" fontWeight="bold">
							{ticket ? `${day} ${month}` : <Skeleton variant="text" width={40} />}
						</Typography>

						<Typography variant="caption" textAlign="center">
							{ticket ? `${hour}:${minute}` : <Skeleton variant="text" width={30} />}
						</Typography>
					</Box>
				</Box>
			</Box>
		</ListItemButton>
	);
};
