import React from 'react';
import { useDialog } from '../redux/slices/dialog';
import { CloseTicketDialog } from './CloseTicketDialog';

export const Dialog = (): JSX.Element => {
	const { isOpen, action } = useDialog();

	if (!isOpen || !action) {
		return <></>;
	}

	switch (action) {
		case 'cancel-ticket':
		case 'close-ticket':
			return <CloseTicketDialog />;
	}
};
