import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import type { RootState } from '..';

export interface SpinnerState {
	isLoading: boolean;
}

const initialState: SpinnerState = {
	isLoading: false,
};

export const spinnerSlice = createSlice({
	name: 'spinner',
	initialState,
	reducers: {
		show: (state) => {
			state.isLoading = true;
		},

		hide: (state) => {
			state.isLoading = false;
		},
	},
});

export const useSpinner = () => useSelector<RootState, SpinnerState>((state) => state.spinner);
