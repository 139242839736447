import { Alert, Snackbar as MuiSnackbar } from '@mui/material';
import React from 'react';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useSnackbar } from '../redux/slices/snackbar';
import type { SnackbarCloseReason } from '@mui/material';

export const Snackbar = (): JSX.Element => {
	const i18n = useI18N();
	const { snackbar } = useRedux();
	const { isOpen, status, message } = useSnackbar();

	const handleClose = (e: Event | React.SyntheticEvent, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		snackbar.hide();
	};

	return (
		<MuiSnackbar
			open={isOpen}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
			autoHideDuration={5000}
			onClose={handleClose}
		>
			<Alert severity={status} variant="filled" onClose={handleClose}>
				{i18n(message)}
			</Alert>
		</MuiSnackbar>
	);
};
