import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SystemModeIcon from '@mui/icons-material/SettingsBrightness';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useTheme } from '../redux/slices/theme';
import { StyledMenu } from './StyledMenu';
import type { ThemeMode } from '../redux/slices/theme';

export const ChangeModeButton = (): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { theme } = useRedux();
	const { mode } = useTheme();

	const [isMenuOpen, setMenuOpen] = useState(false);

	const anchorRef = useRef<HTMLButtonElement>(null);

	let icon;

	switch (mode) {
		case 'light':
			icon = <LightModeIcon />;

			break;

		case 'dark':
			icon = <DarkModeIcon />;

			break;

		case 'system':
			icon = <SystemModeIcon />;

			break;
	}

	const handleClick = (mode: ThemeMode) => {
		theme.update({ mode });
		setMenuOpen(false);
	};

	return (
		<>
			<Tooltip title={i18n('theme.mode.change')}>
				<IconButton
					size={isMobile ? 'small' : 'medium'}
					color="inherit"
					ref={anchorRef}
					onClick={() => setMenuOpen(!isMenuOpen)}
				>
					{icon}
				</IconButton>
			</Tooltip>

			<StyledMenu open={isMenuOpen} anchorEl={anchorRef.current} onClose={() => setMenuOpen(false)}>
				<MenuItem selected={mode === 'light'} onClick={() => handleClick('light')}>
					{i18n('theme.mode.light')}
				</MenuItem>

				<MenuItem selected={mode === 'dark'} onClick={() => handleClick('dark')}>
					{i18n('theme.mode.dark')}
				</MenuItem>

				<MenuItem selected={mode === 'system'} onClick={() => handleClick('system')}>
					{i18n('theme.mode.system')}
				</MenuItem>
			</StyledMenu>
		</>
	);
};
