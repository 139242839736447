import { signInWithCustomToken, signOut } from 'firebase/auth';
import { Api } from './Api';
import { appcenterAuth, inboxAuth } from './firebase';

export const Auth = {
	login: async (userOrToken: string, password: string | boolean): Promise<void> => {
		const response = await Api.auth.login(userOrToken, password);
		await signInWithCustomToken(appcenterAuth, response.tokens.appcenterCustomer);
		await signInWithCustomToken(inboxAuth, response.tokens.inboxticket);
	},

	sso: async (token: string): Promise<void> => {
		return Auth.login(token, false);
	},

	logout: async (): Promise<void> => {
		await signOut(appcenterAuth);
		await signOut(inboxAuth);
	},
};
