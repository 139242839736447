import AppsIcon from '@mui/icons-material/Apps';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Popover,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LogoDokPlus from '../assets/logo_dokplus.png';
import LogoEditFy from '../assets/logo_editfy.png';
import LogoWorkflux from '../assets/logo_workflux.png';
import LogoYopen from '../assets/logo_yopen.png';
import { useIsMobile } from '../hooks';
import { useI18N } from '../i18n';
import { useRedux } from '../redux';
import { useAuth } from '../redux/slices/auth';
import { Api } from '../services/Api';

export interface App {
	id: string;
	title: string;
	logo: string;
	url: string;
}

const apps: App[] = [
	{
		id: 'dokia',
		title: 'Dok+',
		logo: LogoDokPlus,
		url: 'https://dok.plus',
	},
	{
		id: 'autodocs',
		title: 'EditFy',
		logo: LogoEditFy,
		url: 'https://editfy.app',
	},
	{
		id: 'workflux',
		title: 'Workflux',
		logo: LogoWorkflux,
		url: 'https://workflux.digital',
	},
	{
		id: 'collectame',
		title: 'Yopen',
		logo: LogoYopen,
		url: 'https://yopen.app',
	},
];

export const OtherAppsButton = (): JSX.Element => {
	const i18n = useI18N();
	const isMobile = useIsMobile();
	const { snackbar } = useRedux();
	const { user, company } = useAuth();

	const userApps = useMemo(() => {
		return user ? apps.filter((app) => user.hasLicense[app.id]) : [];
	}, [user]);

	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isOpeningApp, setOpeningApp] = useState<boolean[]>([]);

	const anchorRef = useRef<HTMLButtonElement>(null);

	const handleAppClick = async (app: App, i: number) => {
		if (!user?.uid || !company?.uid) {
			return;
		}

		setOpeningApp((prevOpeningApp) => {
			prevOpeningApp[i] = true;

			return [...prevOpeningApp];
		});

		try {
			const { uid: token } = await Api.auth.getSSOToken(user.uid, company.uid, app.id);

			window.open(`${app.url}/sso/${token}`, '_blank');
		} catch (err) {
			snackbar.show({ status: 'error', message: 'apps.errors.goToOther' });
		}

		setOpeningApp((prevOpeningApp) => {
			prevOpeningApp[i] = false;

			return [...prevOpeningApp];
		});
	};

	useEffect(() => {
		setOpeningApp(new Array(userApps.length).fill(false));
	}, [userApps]);

	return userApps.length > 0 ? (
		<>
			<Tooltip title={i18n('apps.goToOther')}>
				<IconButton
					size={isMobile ? 'small' : 'medium'}
					color="inherit"
					ref={anchorRef}
					onClick={() => setMenuOpen(!isMenuOpen)}
				>
					<AppsIcon />
				</IconButton>
			</Tooltip>

			<Popover
				open={isMenuOpen}
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom',
				}}
				onClose={() => setMenuOpen(false)}
			>
				<Grid
					container
					spacing={2}
					sx={{
						width: 250,
						p: 2,
					}}
				>
					{userApps.map((app, i) => (
						<Grid
							key={app.id}
							item
							xs={6}
							sx={{
								flex: 1,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Button
								size="small"
								disabled={isOpeningApp[i]}
								sx={{
									flex: 1,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									gap: 1,
								}}
								onClick={() => handleAppClick(app, i)}
							>
								{isOpeningApp[i] ? (
									<CircularProgress />
								) : (
									<>
										<Box component="img" alt={app.title} src={app.logo} width={36} />

										<Typography variant="caption">{app.title}</Typography>
									</>
								)}
							</Button>
						</Grid>
					))}
				</Grid>
			</Popover>
		</>
	) : (
		<>{null}</>
	);
};
