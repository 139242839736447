import React from 'react';
import { AppContainer } from '../components/AppContainer';
import { LoginForm } from '../components/LoginForm';

export const Login = (): JSX.Element => {
	return (
		<AppContainer
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: 1,
			}}
		>
			<LoginForm />
		</AppContainer>
	);
};
