import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import type { PaletteMode } from '@mui/material';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '..';
import type { Language } from '../../i18n';

export interface ThemeState {
	mode: ThemeMode;
	language: Language;
}

export type ThemeMode = PaletteMode | 'system';

export type ThemePayload = Partial<ThemeState>;

const initialState: ThemeState = {
	mode: 'system',
	language: 'pt-BR',
};

export const themeSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		update: (state, { payload }: PayloadAction<ThemePayload>) => {
			if (payload.mode) {
				state.mode = payload.mode;
			}

			if (payload.language) {
				state.language = payload.language;
			}
		},
	},
});

export const useTheme = () => useSelector<RootState, ThemeState>((state) => state.theme);
