import { Container } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from '../hooks';
import { useRedux } from '../redux';
import { useAuth } from '../redux/slices/auth';
import { useTickets } from '../redux/slices/tickets';
import { Api } from '../services/Api';
import { Header } from './Header';
import type { SxProps, Theme } from '@mui/material';

export interface AppContainerProps {
	sx?: SxProps<Theme>;
	children: React.ReactNode;
}

export const AppContainer = ({ sx, children }: AppContainerProps): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();

	const windowSize = useWindowSize();
	const { tickets } = useRedux();
	const { isLoggingIn, isLoggedIn } = useAuth();
	const { apiData } = useTickets();

	const [appBarHeight, setAppBarHeight] = useState(0);

	const appBarRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isLoggingIn) {
			return;
		}

		if (isLoggedIn) {
			if (location.pathname.startsWith('/login') || location.pathname.startsWith('/sso')) {
				navigate('/');
			}
		} else {
			navigate('/login');
		}
	}, [location.pathname, isLoggingIn, isLoggedIn]);

	useEffect(() => {
		window.setTimeout(() => {
			if (appBarRef.current) {
				setAppBarHeight(appBarRef.current.getBoundingClientRect().height);
			}
		}, 0);
	}, [windowSize, appBarRef.current]);

	useEffect(() => {
		const updateApiData = async () => {
			if (apiData.users.length === 0) {
				const newApiData = {
					groups: await Api.tickets.listGroups(),
					types: await Api.tickets.listTypes(),
					states: await Api.tickets.listStates(),
					users: await Api.tickets.listUsers(),
				};
				tickets.update({ apiData: newApiData });
			}
		};

		void updateApiData();
	}, [apiData]);

	return (
		<>
			<Header ref={appBarRef} />
			<Container
				maxWidth={false}
				sx={{
					maxHeight: `calc(100vh - ${appBarHeight}px)`,
					overflow: 'auto',
					...(sx ?? {}),
				}}
			>
				{(isLoggedIn ||
					location.pathname.startsWith('/login') ||
					location.pathname.startsWith('/sso')) &&
					children}
			</Container>
		</>
	);
};
